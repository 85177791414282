import React, { useEffect, useRef, useState } from "react";
import { DeleteLeadNote, editLead, getInReviewsLeadList, getIndustrylist, chargeBuyerApi, cancelTransactionApi } from "../../api/apiHandler";
import $ from "jquery";
import FeatherIcon from "feather-icons-react";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Swal from "sweetalert2";
import { renderToStaticMarkup } from "react-dom/server";
import { Await, Link, useNavigate, useParams } from "react-router-dom";
import { bodyDecryption, bodyEncryption } from "../common/RedirectPathMange";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useFieldArray, useForm } from "react-hook-form";
import { Form, Image } from "react-bootstrap";
import Select from "react-select";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import S3 from 'react-aws-s3';
import AWS from "../../AWS"
import { useDropzone } from "react-dropzone";
import moment from 'moment';
import { Modal } from 'react-bootstrap';

window.Buffer = window.Buffer || require("buffer").Buffer;

const S3_BUCKET = process.env.REACT_APP_BUCKET_NAME;
const REGION = process.env.REACT_APP_REGION;
const ACCESS_KEY = process.env.REACT_APP_AWS_ACCESS_KEY_ID;
const SECRET_ACCESS_KEY = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;

const config = {
    bucketName: S3_BUCKET,
    dirName: process.env.REACT_APP_LEAD_IMAGES,
    region: REGION,
    accessKeyId: ACCESS_KEY,
    secretAccessKey: SECRET_ACCESS_KEY,
    s3Url: process.env.REACT_APP_AWS_S3_URL,
};

const configDoc = {
  bucketName: S3_BUCKET,
  dirName: process.env.REACT_APP_LEAD_DOCUMENTS,
  region: REGION,
  accessKeyId: ACCESS_KEY,
  secretAccessKey: SECRET_ACCESS_KEY,
  s3Url: process.env.REACT_APP_AWS_S3_URL,
};

const configRec = {
  bucketName: S3_BUCKET,
  dirName: process.env.REACT_APP_LEAD_RECORDINGS,
  region: REGION,
  accessKeyId: ACCESS_KEY,
  secretAccessKey: SECRET_ACCESS_KEY,
  s3Url: process.env.REACT_APP_AWS_S3_URL,
};

const ReactS3ClientImg = new S3(config);
const ReactS3ClientDoc = new S3(configDoc);
const ReactS3ClientRec = new S3(configRec);

function InReviewLeadEdit() {
  let { id } = useParams();
  var decId = decodeURIComponent(bodyDecryption(id));

  // s3 bucket image

  const s3 = new AWS.S3();
  const [files, setFiles] = useState([]);
  const awsS3Url = process.env.AWS_S3_URL;
  
  const onDrop = (acceptedFiles) => {
      const filteredFiles = acceptedFiles.filter((file) => {
        //return file.type.startsWith("image/") && file.size <= 200 * 1024; // 200 KB limit
        return file;
      });
      setFiles([...files, ...filteredFiles]); 
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    // accept: "image/*", // Allow only image file types
  });

  const [randomName, setRandomName] = useState([]);

  const [leadDetails, setLeadDetails] = useState(null);
  const [pageNo, setPageNo] = useState(1);
  const [industrysList, setIndustryList] = useState([]);

  const [selectedOption, setSelectedOption] = useState(null);
  const [Option, setOptions] = useState([]);

  const [address, setAddress] = useState("");
  const [coordinates, setCoordinates] = useState(null);
  const [loader, setLoader] = useState(false);

  const [selectedAppoinmentDate, setSelectedAppoinmentDate] = useState("");
  const localAppointmentDate = moment.utc(selectedAppoinmentDate).local().format('YYYY-MM-DD HH:mm:ss');

  const [updatedNoteContent, setUpdatedNoteContent] = useState({});
  const [updatedRecoredContent, setUpdatedRecoredContent] = useState({});
  const [updatedDocumentContent, setUpdatedDocumentContent] = useState({});
  const [selectedDisposition, setSelectedDisposition] = useState("");

  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedDocuments, setSelectedDocumetns] = useState([]);
  const [selectedRecordings, setSelectedRecordings] = useState([]);
  const [selectedNotes, setSelectedNotes] = useState([]);
  const [selectedFields, setSelectedFields] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [modalImageSrc, setModalImageSrc] = useState("");

  const [imge, setImge] = useState([]);
  const [upd, setUpd] = useState([]);

  const [docs, setDocs] = useState([]);
  const [updDocs, setUpdDocs] = useState([]);

  const [recording, setRecording] = useState([]);
  const [updRecording, setUpdRecording] = useState([]);

  const [hide, setHide] = useState(false);

  const fileRef = useRef();

  const [currentAudio, setCurrentAudio] = useState(null);
  const audioRef = useRef(null);

  const [leadPrice, setLeadPrice] = useState(null);
  const [adminCommission, setAdminCommission] = useState(null);

  const handlePlay = (audioElement) => {
      if (currentAudio && currentAudio !== audioElement) {
          currentAudio.pause();
      }
      setCurrentAudio(audioElement);
  };

  // for lead price edit before start time after end time
  const [currentDateTime, setCurrentDateTime] = useState(new Date().toISOString());
  
  // const [isEditable, setIsEditable] = useState(false); 
  // useEffect(() => {
  //   if(leadDetails && leadDetails.set_bids.start_date_time && leadDetails.set_bids.end_date_time){
  //     const currentDateTime = new Date();
  //     const startDateTime = leadDetails.set_bids.start_date_time;
  //     const endDateTime = leadDetails.set_bids.end_date_time;

  //     if (currentDateTime < startDateTime || currentDateTime > endDateTime) {
  //       setIsEditable(true);
  //     } else {
  //       setIsEditable(false);
  //     }
  //   }
  // }, [leadDetails]);

  const imgchnge = async (e) => {
      setImge([])
      setUpd([])
      var abc = []
      var length =  0
      const files = Array.from(e.target.files);
      imge.push(files)
      setLoader(true);

      imge[0].forEach((file) => {
          var newfile = new Date().getTime() + file.name
          ReactS3ClientImg.uploadFile(file, newfile).then(data => {
              if (data.status === 204) {
                  ++length
                  abc.push(data.location.split("/")[5]);
                  if(length == e.target.files.length){
                    setUpd(abc)
                    setLoader(false);
                  }
                  setLoader(false);
              } else {
                  setLoader(false);
                  console.log('obj err')
              }
          });
      });
  }

  const docschnge = async (e) => {
    setDocs([])
    setUpdDocs([])
    var abc = []
    var length =  0
    const files = Array.from(e.target.files);
    docs.push(files)
    setLoader(true);

    docs[0].forEach((file) => {
        var newfile = new Date().getTime() + file.name
        ReactS3ClientDoc.uploadFile(file, newfile).then(data => {
            if (data.status === 204) {
                ++length
                const docDatas = {
                  "documents" : data.location.split("/")[5],
                  "dummy_documents" : data.location.split("/")[5]
                }
                abc.push(docDatas);
                
                if(length == e.target.files.length){
                  setUpdDocs(abc)
                  setLoader(false);
                }
                setLoader(false);
            } else {
                setLoader(false);
                console.log('obj err')
            }
        });
    });
  }

  const recodingchnge = async (e) => {
    setRecording([])
    setUpdRecording([])
    var abc = []
    var length =  0
    const files = Array.from(e.target.files);
    recording.push(files)
    setLoader(true);

    recording[0].forEach((file) => {
        var newfile = new Date().getTime() + file.name
        ReactS3ClientRec.uploadFile(file, newfile).then(data => {
            if (data.status === 204) {
                ++length
                const recDatas = {
                  "recordings_name" : data.location.split("/")[5],
                  "dummy_recording" : data.location.split("/")[5]
                }
                abc.push(recDatas);
                if(length == e.target.files.length){
                  setUpdRecording(abc)
                  setLoader(false);
                }
                setLoader(false);
            } else {
                setLoader(false);
                console.log('obj err')
            }
        });
    });
  }

  useEffect(() => {
    if(upd.length > 0){
      setSelectedImages(selectedImages.concat(upd))
    }
  }, [upd]);

  useEffect(() => {
    if(updDocs.length > 0){
      setSelectedDocumetns(selectedDocuments.concat(updDocs))
    }
  }, [updDocs]);

  useEffect(() => {
    if(updRecording.length > 0){
      setSelectedRecordings(selectedRecordings.concat(updRecording))
    }
  }, [updRecording]);

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    // when multiple opt show
    // const industryIds = selectedOption.map((option) => option.value);
    // setValue('industry', industryIds);

    setValue("industry", selectedOption);
    setError("industry", "");
    //
  };

  const deleteImage = async (img) => {
    setLoader(true);
    let index = selectedImages.indexOf(img);
    selectedImages.splice(index, 1);
    setSelectedImages([])
    setTimeout(() => {
      setSelectedImages(selectedImages)
      setLoader(false);
    }, 500);
  };

  function handleImageDownload(image) {
    // const anchor = document.createElement('a');
    // anchor.href = url;
    // anchor.target = '_blank';
    // anchor.download = url.substring(url.lastIndexOf('/') + 1);
    // anchor.style.display = 'none';
    // document.body.appendChild(anchor);
    // anchor.click();
    // document.body.removeChild(anchor);

    let img_url = `${process.env.REACT_APP_AWS_S3_URL}` + `${process.env.REACT_APP_LEAD_IMAGES}` + '/' + image;
    console.log('img_url: ', img_url);
    window.open(img_url, '_blank');
  }

  const deleteDocuments = async (documents) => {
    setLoader(true);
    let index = selectedDocuments.indexOf(documents);
    selectedDocuments.splice(index, 1);
    setSelectedDocumetns([])
    setTimeout(() => {
      setSelectedDocumetns(selectedDocuments)
      setLoader(false);
    }, 500);
  };

  const deleteRecordings = async (recordings) => {
    setLoader(true);
    let index = selectedRecordings.indexOf(recordings);
    selectedRecordings.splice(index, 1);
    setSelectedRecordings([])
    setTimeout(() => {
      setSelectedRecordings(selectedRecordings)
      setLoader(false);
    }, 500);
  };

  const deleteNotes = async (notes) => {
    setLoader(true);
    let index = selectedNotes.indexOf(notes);
    selectedNotes.splice(index, 1);
    setSelectedNotes([])
    setTimeout(() => {
      setSelectedNotes(selectedNotes)
      setLoader(false);
    }, 500);
  };

  useEffect(() => {
    if (id != undefined) {
      setLoader(true)
      getInReviewsLeadList({
        lead_id: decId,
        page: pageNo,
        record_count: parseInt(process.env.REACT_APP_RECORD_COUNT),
      }).then((res) => {
        res = JSON.parse(res);
        if (res.code == 200) {
          //
          // when multiple then this comment
          setSelectedOption(res.data.Leads[0].industry);
          setValue("industry", res.data.Leads[0].industry);
          
          setSelectedDisposition(res.data.Leads[0].disposition);
          setSelectedAppoinmentDate(res.data.Leads[0].appointment_date)

          // setSelectedOption(res.data.Leads[0].industry.map(industry => ({
          //   value: industry.id,
          //   label: industry.industry_name
          // })));
          setAddress(res.data.Leads[0].address);
          setValue("address", res.data.Leads[0].address);
          setValue("latitude", res.data.Leads[0].latitude);
          setValue("longitude", res.data.Leads[0].longitude);
          if(res.data.Leads[0].lead_images.length > 0){
            var imageData = []
            res.data.Leads[0].lead_images.map((item) => {
              imageData.push(item.images)
            })
            setSelectedImages(imageData);
          }

          if(res.data.Leads[0].lead_notes_data.length > 0){
            var notesData = []
            res.data.Leads[0].lead_notes_data.map((item) => {
              const noteData = {
                "id" : item.id,
                "title" : item.title,
                "notes" : item.notes,
                "is_visibility" : item.is_visibility,
              }
              notesData.push(noteData)
            })
            appendNotes(notesData);
            setSelectedNotes(notesData);
          }

          if(res.data.Leads[0].lead_field_data.length > 0){
            var fieldsData = []
            res.data.Leads[0].lead_field_data.map((item) => {
              const fieldData = {
                "id" : item.id,
                "field_name" : item.field_name,
                "description" : item.description,
              }
              fieldsData.push(fieldData)
            })
            appendCustom(fieldsData);
            setSelectedFields(fieldsData);
          }

          if(res.data.Leads[0].lead_documents.length > 0){
            var documentData = []
            res.data.Leads[0].lead_documents.map((item) => {
              const docData = {
                "id" : item.id,
                "documents" : item.documents,
                "dummy_documents" : item.dummy_documents,
                "document_show" : item.document_show,
              }
              documentData.push(docData)
            })
            setSelectedDocumetns(documentData);
          }

          if(res.data.Leads[0].lead_recordings.length > 0){
            var recordingData = []
            res.data.Leads[0].lead_recordings.map((item) => {
              const recData = {
                "id" : item.id,
                "recordings_name" : item.recordings_name,
                "dummy_recording" : item.dummy_recording,
                "recording_show" : item.recording_show,
              }
              recordingData.push(recData)
            })
            setSelectedRecordings(recordingData);
          }

          setLeadDetails(res.data.Leads[0]);
          // setLeadPrice(res.data.Leads[0].lead_price);
          // setAdminCommission(res.data.Leads[0].admin_commission);
          setLoader(false);
        } else {
          setLoader(false);
          setLeadDetails([]);
        }
      });
    }
  }, [id]);

  useEffect(() => {
    getIndustrylist({
      page: pageNo,
      record_count: parseInt(process.env.REACT_APP_RECORD_COUNT),
    }).then((response) => {
      response = JSON.parse(response);
      
      if (response.code === 200) {
        setIndustryList(response?.data?.Industry);
        // console.log("object",response?.data?.Industry)
        // when multiple
        // setOptions((prevOptions) => {
        //   return response.data.Industry.map((industry) => ({
        //     value: industry.id,
        //     label: industry.industry_name,
        //   }));
        // });
        // setValue("industry", response.data.Industry);

        //
      } else {
        setIndustryList([]);
      }
    });
  }, []);

  useEffect(() => {
    if(loader){
      Swal.fire({
      title: 'Please wait...',
      didOpen: () => {
          Swal.showLoading();
      }
    })
    } else {
      Swal.close();
    }
  }, [loader]);

  const validationSchema = Yup.object().shape({
    // industry: Yup.array().min(1).required("Please Select Industry"),
    // industry: Yup.string().required("Please Select Industry"),
    // category: Yup.string().required("Please select category"),
    // name: Yup.string().required("Please enter name"),
    // email: Yup.string().required("Please enter email address"),
    // phone_number: Yup.string().required("Please enter phone number"),
    // secondary_phone_number: Yup.string().required(
    //   "Please enter secondary phone number"
    // ),
    // address: Yup.string().required("Please enter address"),
    // latitude: Yup.string().required("Please enter latitude"),
    // longitude: Yup.string().required("Please enter longitude"),
    appointment_date: Yup.string().required("Please select appointment date"),
    status: Yup.string().required("Please select status"),
    badges: Yup.string().required("Please select badges"),
    // disposition: Yup.string().required("Please select disposition"),
    // disposition_note: Yup.string().required("Please select disposition note"),
    // dispos_resch_date : selectedDisposition === 'Rescheduled'? Yup.string().required('Please select Rescheduled date & time'): Yup.string(),
    // dispos_resch_time: selectedDisposition === 'Rescheduled'? Yup.string().required('Please select Rescheduled time'): Yup.string(),
    leadNotesData: Yup.array()
            .of(
                Yup.object().shape({
                    // _id: Yup.string(),
                    // title: Yup.string().required('Title is required'),
                    notes: Yup.string().required('Notes is required'),
                })
            ),
    leadCustomFieldsData: Yup.array()
            .of(
                Yup.object().shape({
                    // _id: Yup.string(),
                    // field_name: Yup.string().required('Title is required'),
                    description: Yup.string().required('Field is required'),
                })
            ),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, formState, setValue, setError, watch, control } =
    useForm(formOptions);
  const { errors } = formState;
  watch(formOptions);

  const { fields: notesFields, append: appendNotes, remove: removeNotes } = useFieldArray({
    control,
    name: "leadNotesData"
  });

  const { fields: customFields, append: appendCustom, remove: removeCustom } = useFieldArray({
    control,
    name: "leadCustomFieldsData"
  });

  const navigate = useNavigate();

  function redirect() {
    navigate("/inReviewList");
  }

  const onSubmit = async (data) => {

    if(data.admin_commission){
      if(data.admin_commission <= 0 || data.admin_commission >= 101){ 
        return Swal.fire({
          position: 'top-end',
          icon: 'warning',
          title: "Commission must be between 1 to 100",
          toast: true,
          showConfirmButton: false,
          timer: 1500
        })
      }else {
        await handleEditLeadData(data)
      }
    }else {
      await handleEditLeadData(data)
    }
    
  };

  const handleEditLeadData = async (data) => {
    const updatedNotes = Object.entries(updatedNoteContent).map(
      ([id, content]) => ({
        id,
        notes: content,
      })
    );

    const updatedRecording = Object.entries(updatedRecoredContent).map(
      ([id, recordingContent]) => ({
        id,
        // recordings_name: recordingContent,
        dummy_recording: recordingContent,
      })
    );

    const updatedDocuments = Object.entries(updatedDocumentContent).map(
      ([id, documentsContent]) => ({
        id,
        // documents: documentsContent,
        dummy_documents: documentsContent,
      })
    );

    // await UploadRecordings();
    // await UploadDocumetns();

    // when multiple opt show
    // const industryOpt = selectedOption.map((e) => e.value).join(",");
    // ----- upper
    // const industryId = industryOpt.map((e) => e.join(","))
    //

    // this is old
    // editLead({
    //   lead_id: decId,
    //   industry: data.industry,
    //   category: data.category,
    //   name: data.name.trimStart().trimEnd(),
    //   email: data.email.trimStart().trimEnd(),
    //   phone_number: data.phone_number.trimStart().trimEnd(),
    //   secondary_phone_number: data.secondary_phone_number.trimStart().trimEnd(),
    //   address: data.address,
    //   latitude: data.latitude,
    //   longitude: data.longitude,
    //   is_virtual: data.is_virtual,
    //   appointment_date: data.appointment_date,
    //   follow_up_date: data.follow_up_date,
    //   follow_up_time: data.follow_up_time,
    //   follow_up_note: data.follow_up_note.trimStart().trimEnd(),
    //   status: data.status,
    //   priority: data.priority,
    //   badges: data.badges,
    //   disposition: data.disposition,
    //   disposition_note: data.disposition_note,
    //   dispos_resch_date: data.dispos_resch_date,
    //   dispos_resch_time: data.dispos_resch_time,
    //   images: selectedImages,
    //   documents: selectedDocuments,
    //   leadNotesData: selectedNotes,
    //   recordings_name: selectedRecordings,
    //   lead_notes_data: updatedNotes,
    //   lead_recordings: updatedRecording,
    //   lead_documents: updatedDocuments,
    // }).then((response) => {
    //   response = JSON.parse(response);
    //   if (response.code == 200) {
    //     Swal.fire({
    //       position: "top-end",
    //       icon: "success",
    //       title: response.message,
    //       toast: true,
    //       showConfirmButton: false,
    //       timer: 2000,
    //     });
    //     setTimeout(() => {
    //       setRandomName([]);
    //       setFiles([]);
    //       navigate("/inReviewList");
    //     }, 2000);
    //   } else {
    //     Swal.fire({
    //       position: "top-end",
    //       icon: "error",
    //       title: response.message,
    //       toast: true,
    //       showConfirmButton: false,
    //       timer: 2000,
    //     });
    //   }
    // });

    // this is new
      data.lead_id = decId
      data.name =  data.name.trimStart().trimEnd()
      data.email=  data.email.trimStart().trimEnd()
      data.phone_number = data.phone_number.trimStart().trimEnd()
      data.secondary_phone_number= data.secondary_phone_number.trimStart().trimEnd()
      data.images = selectedImages
      data.documents = selectedDocuments
      data.recordings_name = selectedRecordings
      data.lead_notes_data = updatedNotes
      data.lead_recordings = updatedRecording
      data.lead_documents = updatedDocuments
      data.appointment_date = moment(data.appointment_date).utc().format('YYYY-MM-DD HH:mm:ss');  
      // data.dispos_resch_date = moment(data.dispos_resch_date).utc().format('YYYY-MM-DD HH:mm:ss');
      // console.log("data",data)
    editLead(data).then((response) => {
      response = JSON.parse(response);
      if (response.code == 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: response.message,
          toast: true,
          showConfirmButton: false,
          timer: 2000,
        });
        setTimeout(() => {
          setRandomName([]);
          setFiles([]);
          // navigate("/inReviewList");
          viewLeadDetails(data.lead_id)
        }, 2000);
      } else {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: response.message,
          toast: true,
          showConfirmButton: false,
          timer: 2000,
        });
      }
    });
  }

  const viewLeadDetails = (lead_id) => {
    var encId = encodeURIComponent(bodyEncryption((lead_id).toString()))
    navigate(`/inReviewView/${encId}`);
}

  const handleSelect = async (value) => {
    try {
      const results = await geocodeByAddress(value);
      const latlong = await getLatLng(results[0]);
      //
      //
      setValue("address", results[0].formatted_address);
      setValue("latitude", latlong.lat);
      setValue("longitude", latlong.lng);
      setAddress(value);
      setCoordinates(latlong);
    } catch (error) {}
  };

  const showMessage = (msg) => {
    Swal.fire({
      position: "top-end",
      icon: "success",
      title: msg,
      toast: true,
      showConfirmButton: false,
      timer: 1500,
    });
  };

  function deleteNoteIdWise(noteId) {
    Swal.fire({
      title: "Are you sure you want to delete?",
      text: "You won't be able to revert this!",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#02C9D6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        DeleteLeadNote({ note_id: noteId }).then((response) => {
          response = JSON.parse(response);
          if (response.code === 200) {
            showMessage(response.message);
            getInReviewsLeadList({
              lead_id: decId,
              page: pageNo,
              record_count: parseInt(process.env.REACT_APP_RECORD_COUNT),
            }).then((res) => {
              res = JSON.parse(res);
              if (res.code == 200) {
                //
      
                setLeadDetails(res.data.Leads[0]);
                // when multiple then this comment
                setSelectedOption(res.data.Leads[0].industry);
                setValue("industry", res.data.Leads[0].industry);
                
                setSelectedDisposition(res.data.Leads[0].status);
                setSelectedAppoinmentDate(res.data.Leads[0].appointment_date)
      
                // setSelectedOption(res.data.Leads[0].industry.map(industry => ({
                //   value: industry.id,
                //   label: industry.industry_name
                // })));
                setAddress(res.data.Leads[0].address);
                setValue("address", res.data.Leads[0].address);
                setValue("latitude", res.data.Leads[0].latitude);
                setValue("longitude", res.data.Leads[0].longitude);
                
              } else {
                setLeadDetails([]);
              }
            });
          }
        });
      }
    });
  }

  // const handleNoteContentChange = (noteId, newContent) => {
  //   setUpdatedNoteContent((prevState) => ({
  //     ...prevState,
  //     [noteId]: newContent,
  //   }));
  // };

  const handleNoteContentChange = (index, newNoteContent) => {
    selectedNotes[index].notes = newNoteContent
    setSelectedNotes(selectedNotes);
  };

  const handleNoteTitleChange = (index, newNoteTitle) => {
    selectedNotes[index].title = newNoteTitle
    setSelectedNotes(selectedNotes);
  };

  const handleRecordingContentChange = (index, newRecordContent) => {
    selectedRecordings[index].dummy_recording = newRecordContent
    setSelectedRecordings(selectedRecordings);
  };

  const handleRecordingShowChange = (index, newRecordShowContent) => {
    selectedRecordings[index].recording_show = newRecordShowContent
    setSelectedRecordings(selectedRecordings);
  };

  const handleDocumentsContentChange = (index, newDocumentContent) => {
    selectedDocuments[index].dummy_documents = newDocumentContent
    setSelectedDocumetns(selectedDocuments);
  };

  const handleDocumentsShowChange = (index, newDocumentShowContent) => {
    selectedDocuments[index].document_show = newDocumentShowContent
    setSelectedDocumetns(selectedDocuments);
  };

  // const uploadImage = () => {
  //   fileRef.current.click();
  // };

  // const uploadImageEvent = (e) => {
  //   const imagePreviewUrl = URL.createObjectURL(e.target.files[0]);

  //   setimagePreview(imagePreviewUrl);

  //   // setValue("images", e.target.files[0], true);
  // };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      zIndex: 99999,
      border: state.isFocused ? 0 : 0,
      borderBottom: "1px solid #ccc",
      borderColor: "#fff",
      backgroundColor: "#fff",
      color: state.isSelected ? "#809FB8" : "#809FB8",
      borderColor: state.isFocused ? "white" : "white",
    }),
  };

  const handleDispositionChange = (e) => {
    setSelectedDisposition(e.target.value);
  };

  const handleImageClick = (imageSrc) => {
    setModalImageSrc(imageSrc);
    setShowModal(true);
  };

  const handleCloseModal = () => {
      setShowModal(false);
      setModalImageSrc("");
  };

  const chargeBuyer = (leadId) => {
      Swal.fire({
          title: 'Are you sure ?',
          text: "You won't be able to revert this!",
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#02C9D6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes'
      }).then((result) => {
          if (result.isConfirmed) {
              Swal.fire({
                  title: 'Please wait...',
                  didOpen: () => {
                      Swal.showLoading();
                  }
              });

              const params = {
                "lead_id" : leadId,
                "lead_price" : leadPrice,
                "admin_commission" : adminCommission
              }

              chargeBuyerApi(params).then((response) => {
                  response = JSON.parse(response)
                  if (response.code === 200) {
                      // $('#mangeUser').DataTable().row("#userId" + userId).remove().draw(false);
                      Swal.close();
                      showMessage(response.message);
                      // Update user list after cancel transaction
                      getInReviewsLeadList({ "lead_id": decId,"page": pageNo, "record_count": parseInt(process.env.REACT_APP_RECORD_COUNT) }).then((res) => {
                          res = JSON.parse(res)
                          if (res.code == 200) {
                              setLoader(false);
                              setLeadDetails(res.data.Leads[0])
                              setSelectedDisposition(res.data.Leads[0].disposition);
                          } else {
                              setLoader(false);
                              setLeadDetails([])
                          }
                      });
                  }
              });
          }
      })
  }

  const cancelDetails = (leadId) => {
      Swal.fire({
          title: 'Are you sure you want to cancel transaction?',
          text: "You won't be able to revert this!",
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#02C9D6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes'
      }).then((result) => {
          if (result.isConfirmed) {
              Swal.fire({
                  title: 'Please wait...',
                  didOpen: () => {
                      Swal.showLoading();
                  }
              });
              cancelTransactionApi({ "lead_id": leadId }).then((response) => {
                  response = JSON.parse(response)
                  if (response.code === 200) {
                      // $('#mangeUser').DataTable().row("#userId" + userId).remove().draw(false);
                      Swal.close();
                      showMessage(response.message);
                      // Update user list after cancel transaction
                      getInReviewsLeadList({ "lead_id": decId,"page": pageNo, "record_count": parseInt(process.env.REACT_APP_RECORD_COUNT) }).then((res) => {
                          res = JSON.parse(res)
                          if (res.code == 200) {
                              setLoader(false);
                              setLeadDetails(res.data.Leads[0])
                              setSelectedDisposition(res.data.Leads[0].disposition);
                          } else {
                              setLoader(false);
                              setLeadDetails([])
                          }
                      });
                  }
              });
          }
      })
  }

  if (loader || leadDetails == null) return <loader/>;

  return (
    <>
      <div className="content-page mt-2">
        <div className="content">
          <div className="container-fluid">
            <div className="cal">
              {/* <div className="row clearfix d-flex justify-content-center text-center" > */}
              <div className="col-lg-12 col-md-12">
                <h2 className="text-center mb-3">Edit Lead</h2>
                {/* <div className="body"> */}
                {
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                      {leadDetails &&
                      leadDetails?.industry !== undefined &&
                      leadDetails &&
                      leadDetails?.industry !== "" ? (
                        <div className="form-group col-lg-6">
                          <label className=""> Industry </label>
                          <div>
                            {/* <input type="text" {...register('industry')} className="form-control" defaultValue={leadDetails.industry} placeholder="Please Enter Industry" /> */}
                            {industrysList.length > 0 ? (
                              <select
                                aria-label="Default select example"
                                className="form-control"
                                // defaultValue={leadDetails?.industry}
                                defaultValue={leadDetails?.industry }
                                {...register("industry")}
                              >
                                <option value="">Open this select menu</option>
                                {industrysList?.map((industry, index) => {
                                  return (
                                    <option
                                      value={industry?.industry_name}
                                      key={index}
                                    >
                                      {industry?.industry_name}
                                    </option>
                                  );
                                })}
                              </select>
                            ) : (
                              <></>
                            )}

                            {/* <Select
                            isMulti
                            name="industry"
                            options={Option}
                            value={selectedOption}
                            onChange={handleChange}
                            styles={customStyles}
                            isSearchable
                            isClearable
                          /> */}

                            {/* <div className="invalid-feedback"> */}
                              {/* {selectedOption == null ? (errors.industry && errors.industry.message) : null} */}
                              {/* {errors.industry?.message} */}
                            {/* </div> */}
                          </div>
                        </div>
                      ) : (
                        <>
                          <div className="form-group col-lg-6">
                          <label className=""> Industry </label>
                            <div>
                              <select
                                aria-label="Default select example"
                                className="form-control"
                                // defaultValue={leadDetails?.industry}
                                defaultValue={leadDetails?.industry}
                                {...register("industry")}
                              >
                                <option value="">Open this select menu</option>
                                {industrysList?.map((industry, index) => {
                                  return (
                                    <option
                                      value={industry?.industry_name}
                                      key={index}
                                    >
                                      {industry?.industry_name}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                        </>
                      )}

                      <div className="form-group col-lg-6">
                        <label className="required"> Category </label>
                        <div>
                          {/* <input type="text" {...register('priority')} className="form-control" defaultValue={leadDetails.priority} placeholder="Please Enter Priority" /> */}
                          <Form.Select
                            aria-label="Default select example"
                            className="form-control"
                            {...register("category")}
                            defaultValue={leadDetails.category}
                            disabled
                          >
                            <option value="">Open this select menu</option>
                            <option value="In review">In review</option>
                            <option value="To be verified">Pending</option>
                            <option value="Verified">Approved</option>
                          </Form.Select>
                          {/* <div className="invalid-feedback">
                            {errors.category?.message}
                          </div> */}
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="form-group col-lg-6">
                        <label className="required"> Name </label>
                        <div>
                          <input
                            type="text"
                            {...register("name")}
                            className="form-control"
                            defaultValue={leadDetails.name}
                            placeholder="Please Enter Name"
                          />
                          <div className="invalid-feedback">
                            {errors.name?.message}
                          </div>
                        </div>
                      </div>
                      <div className="form-group col-lg-6">
                        <label> Email </label>
                        <div>
                          <input
                            type="text"
                            {...register("email")}
                            className="form-control"
                            defaultValue={leadDetails.email}
                            placeholder="Please Enter Email"
                          />
                          <div className="invalid-feedback">
                            {errors.email?.message}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="form-group col-lg-6">
                        <label> Phone Number </label>
                        <div>
                          <input
                            type="text"
                            {...register("phone_number")}
                            // className={`form-control ${leadDetails.is_showcontactinfo == 0 ? "blurred" : ""}`}
                            className="form-control"
                            defaultValue={leadDetails.phone_number}
                            placeholder="Please Enter Phone Number"
                          />
                          <div className="invalid-feedback">
                            {errors.phone_number?.message}
                          </div>
                        </div>
                      </div>
                      <div className="form-group col-lg-6">
                        <label className="">
                          {" "}
                          Secondary Phone Number{" "}
                        </label>
                        <div>
                          <input
                            type="text"
                            {...register("secondary_phone_number")}
                            // className={`form-control ${leadDetails.is_showcontactinfo == 0 ? "blurred" : ""}`}
                            className="form-control"
                            defaultValue={leadDetails.secondary_phone_number}
                            placeholder="Please Enter Secondary Phone Number"
                          />
                          {/* <div className="invalid-feedback">
                            {errors.secondary_phone_number?.message}
                          </div> */}
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="form-group col-lg-12">
                        <label className="required"> Address </label>
                        <div>
                          <PlacesAutocomplete
                            value={address}
                            onChange={setAddress}
                            onSelect={handleSelect}
                          >
                            {({
                              getInputProps,
                              suggestions,
                              getSuggestionItemProps,
                              loading,
                            }) => (
                              <div>
                                <input
                                  {...getInputProps({
                                    placeholder: "Enter address...",
                                    className: "form-control",
                                  })}
                                />
                                <div>
                                  {loading ? <div>Loading...</div> : null}

                                  {suggestions.map((suggestion) => {
                                    const style = {
                                      backgroundColor: suggestion.active
                                        ? "#41b6e6"
                                        : "#fff",
                                    };
                                    return (
                                      <div
                                        {...getSuggestionItemProps(suggestion, {
                                          style,
                                        })}
                                      >
                                        {suggestion.description}
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            )}
                          </PlacesAutocomplete>
                          <div className="invalid-feedback">
                            {errors.address?.message}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="form-group col-lg-6">
                        <label className="required"> Appointment Date & Time </label>
                        <div>
                          <input
                            type="datetime-local"
                            {...register("appointment_date")}
                            className="form-control"
                            // defaultValue={localAppointmentDate}
                            defaultValue={ moment.utc(leadDetails.appointment_date).local().format('YYYY-MM-DD HH:mm:ss') }
                            placeholder="Please Enter Appointment Date"
                          />
                          <div className="invalid-feedback">
                            {errors.appointment_date?.message}
                          </div>
                        </div>
                      </div>
                      <div className="form-group col-lg-6">
                        <label> Virtual </label>
                        <div className="form-control">
                          <div className="form-check form-check-inline ">
                            <input
                              defaultChecked={
                                leadDetails.is_virtual === 1 ? true : false
                              }
                              {...register("is_virtual")}
                              className="form-check-input"
                              type="radio"
                              name="is_virtual"
                              id="yes"
                              value={1}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="inlineRadio1"
                            >
                              {" "}
                              Yes{" "}
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              defaultChecked={
                                leadDetails.is_virtual === 0 ? true : false
                              }
                              {...register("is_virtual")}
                              className="form-check-input"
                              type="radio"
                              name="is_virtual"
                              id="no"
                              value={0}
                            />
                            <label
                              className="form-check-label"
                              for="inlineRadio2"
                            >
                              {" "}
                              No{" "}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="form-group col-lg-6">
                        <label> Lead Price </label>
                        <div>
                          <input
                            type="number"
                            {...register("lead_price")}
                            className="form-control"
                            // defaultValue={leadDetails.lead_price}
                            // placeholder="Please Enter Lead Price"
                            // defaultValue={}
                            placeholder={leadDetails.lead_price ? leadDetails.lead_price : leadDetails.higher_current_price ?  leadDetails.higher_current_price.price : leadDetails.set_bids.starting_bid_price}
                            onChange={(e)=>setLeadPrice(e.target.value)}
                            disabled={(currentDateTime > new Date(leadDetails.set_bids.start_date_time).toISOString() && currentDateTime < new Date(leadDetails.set_bids.end_date_time).toISOString()) || leadDetails.is_paid == 2}
                          />
                        </div>
                      </div>
                      <div className="form-group col-lg-6">
                        <label className="required"> Status </label>
                        <div>
                          {/* <input type="text" {...register('status')} className="form-control" defaultValue={leadDetails.status} placeholder="Please Enter S" 
                                                            /> */}
                          <Form.Select
                            aria-label="Default select example"
                            className="form-control"
                            {...register("status")}
                            defaultValue={leadDetails.status}
                            onChange={handleDispositionChange}
                          >
                            <option value="">Open this select menu</option>
                            <option value="Live">Live</option>
                            <option value="Pending">Pending</option>
                            <option value="Held">Held</option>
                            <option value="Rescheduled">Rescheduled</option>
                            <option value="Missed">Missed</option>
                            <option value="No Show">No Show</option>
                            <option value="Credit/Insurance Fail">
                              Credit/Insurance Fail
                            </option>
                            <option value="Cancelled">Cancelled</option>
                            <option value="Unsold">Unsold</option>
                            <option value="In-Review">In-Review</option>
                            <option value="New Lead">New Lead</option>
                            <option value="In-Contact">In-Contact</option>
                            <option value="No Response">No Response</option>
                            <option value="No Interest">No Interest</option>
                            <option value="Renter">Renter</option>
                            <option value="Disqualified">Disqualified</option>
                            <option value="Under Contract">
                              Under Contract
                            </option>
                            <option value="Homeowner Not Home 1">
                              Homeowner Not Home 1
                            </option>
                            <option value="Homeowner Not Home 2">
                              Homeowner Not Home 2
                            </option>
                            <option value="Homeowner Not Home 3">
                              Homeowner Not Home 3
                            </option>
                            <option value="Moving">Moving</option>
                            <option value="Has Solar">Has Solar</option>
                            <option value="Non-Decision-Maker">
                              Non-Decision-Maker
                            </option>
                            <option value="Bad Credit">Bad Credit</option>
                            <option value="Shading">Shading</option>
                            <option value="Spanish Speaker">
                              Spanish Speaker
                            </option>
                          </Form.Select>
                          <div className="invalid-feedback">
                            {errors.status?.message}
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* {selectedDisposition === "Rescheduled" && (
                      <div className="row">
                        <div className="form-group col-lg-12">
                          <label className="required"> Reschdule Date & Time </label>
                          <div>
                            <input
                              type="datetime-local"
                              {...register("dispos_resch_date")}
                              className="form-control"
                              defaultValue={ moment.utc(leadDetails.dispos_resch_date).local().format('YYYY-MM-DD HH:mm:ss') }
                            />
                            <div className="invalid-feedback">
                              {errors.dispos_resch_date?.message}
                            </div>
                          </div>
                        </div>
                      </div>
                    )} */}

                    <div className="row">
                      <div className="form-group col-lg-6">
                        <label> Admin Commission % ( default commission {leadDetails.admin_commission} % ) </label>
                        <div>
                          <input
                            type="number"
                            {...register("admin_commission")}
                            className="form-control"
                            // defaultValue={}
                            placeholder={leadDetails.admin_commission}
                            onChange={(e)=>setAdminCommission(e.target.value)}
                            disabled={leadDetails.is_paid == 2}
                          />
                        </div>
                      </div>
                      <div className="form-group col-lg-6">
                        <label className="required"> Badges </label>
                        <div>
                          {/* <input type="text" {...register('priority')} className="form-control" defaultValue={leadDetails.priority} placeholder="Please Enter Priority" /> */}
                          <Form.Select
                            aria-label="Default select example"
                            className="form-control"
                            {...register("badges")}
                            defaultValue={leadDetails.badges}
                          >
                            <option value="">Open this select menu</option>
                            <option value="Not-verified">Not-verified</option>
                            <option value="Verified">Verified</option>
                            <option value="Multi-Post">Multi-Post</option>
                            <option value="Re-Post">Re-Post</option>
                          </Form.Select>
                          <div className="invalid-feedback">
                            {errors.badges?.message}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="form-group col-lg-6">
                        <label> Active/Inactive </label>
                        <div className="form-control">
                          <div className="form-check form-check-inline ">
                            <input
                              defaultChecked={
                                leadDetails.is_active === 1 ? true : false
                              }
                              {...register("is_active")}
                              className="form-check-input"
                              type="radio"
                              name="is_active"
                              id="Active"
                              value={1}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="inlineRadio1"
                            >
                              {" "}
                              Active{" "}
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              defaultChecked={
                                leadDetails.is_active === 0 ? true : false
                              }
                              {...register("is_active")}
                              className="form-check-input"
                              type="radio"
                              name="is_active"
                              id="Inactive"
                              value={0}
                            />
                            <label
                              className="form-check-label"
                              for="inlineRadio2"
                            >
                              {" "}
                              Inactive{" "}
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="form-group col-lg-6">
                        <label> Show Contact Info </label>
                        <div className="form-control">
                          <div className="form-check form-check-inline ">
                            <input
                              defaultChecked={
                                leadDetails.is_showcontactinfo === 1 ? true : false
                              }
                              {...register("is_showcontactinfo")}
                              className="form-check-input"
                              type="radio"
                              name="is_showcontactinfo"
                              id="is_showcontactinfo_hide"
                              value={1}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="inlineRadio1"
                            >
                              {" "}
                              Yes{" "}
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              defaultChecked={
                                leadDetails.is_showcontactinfo === 0 ? true : false
                              }
                              {...register("is_showcontactinfo")}
                              className="form-check-input"
                              type="radio"
                              name="is_showcontactinfo"
                              id="is_showcontactinfo_show"
                              value={0}
                            />
                            <label
                              className="form-check-label"
                              for="inlineRadio2"
                            >
                              {" "}
                              No{" "}
                            </label>
                          </div>
                        </div>
                      </div>
                      {/* <div className="form-group col-lg-6">
                        <label className=""> Disposition </label>
                        <div>
                          <Form.Select
                            aria-label="Default select example"
                            className="form-control"
                            {...register("disposition")}
                            defaultValue={leadDetails.disposition}
                            onChange={handleDispositionChange}
                          >
                            <option value="">Open this select menu</option>
                            <option value="Update">Update</option>
                            <option value="Cancel">Cancel</option>
                            <option value="Reschedule">Reschedule</option>
                          </Form.Select> */}
                          {/* <div className="invalid-feedback">
                            {errors.disposition?.message}
                          </div> */}
                        {/* </div>
                      </div> */}
                    </div>

                    {/* <div className="row">
                      <div className="form-group col-lg-12">
                        <label> Disposition Note </label>
                        <div>
                          <textarea
                            rows="3"
                            {...register("disposition_note")}
                            className="form-control"
                            defaultValue={leadDetails.disposition_note}
                            placeholder="Please Enter Disposition Note"
                          />
                        </div> */}
                        {/* <div className="invalid-feedback">
                          {errors.disposition_note?.message}
                        </div> */}
                      {/* </div>
                    </div> */}

                    <div className="row">
                      <div className="form-group col-lg-6">
                      {leadDetails && leadDetails.is_paid == 1 ? 
                        <>
                          <Link style={{backgroundColor:"green", color:"white"}} className="btn btn-lg common_hover mx-2" onClick={() => { chargeBuyer(leadDetails.id) }}>
                              <i className=""></i> <span> Charge Buyer </span>
                          </Link>

                          <Link style={{backgroundColor:"red", color:"white"}} className="btn btn-lg mx-2" onClick={() => { cancelDetails(leadDetails.id) }}>
                              <i className=""></i> <span> Cancel Transaction </span>
                          </Link>
                        </> : 
                        <>
                          <button style={{backgroundColor:"grey", color:"white"}} className="btn btn-lg x-2" disabled={leadDetails.is_paid == 2}>
                              <i className=""></i> <span> Charge Buyer </span>
                          </button>

                          <button style={{backgroundColor:"grey", color:"white"}} className="btn btn-lg mx-2" disabled={leadDetails.is_paid == 2}>
                              <i className=""></i> <span> Cancel Transaction </span>
                          </button>
                        </> }
                      </div>
                      {/* <div className="form-group col-lg-6">
                        <label className="font-weight-bold"> Setter : <a className="text-primary" style={{ fontSize: '15px', cursor: 'pointer', textDecoration: 'underline' }} >{"Alan Renzikow"}</a> </label> <br></br>
                        <label className="font-weight-bold"> Buyer : <a className="text-primary" style={{ fontSize: '15px', cursor: 'pointer', textDecoration: 'underline' }} >{"Alan Renzikow"}</a> </label>
                      </div> */}
                    </div>

                    <div className="form-group col-lg-12">
                      <div>
                        <label> Title & Description </label>  
                      </div>

                      <div>
                        <ul className="list-group">
                          <li className="list-group-item">
                            <div className="note-content d-flex">
                              <div className="border p-1">
                                <div className="d-flex mb-2">
                                      <span>Title : </span>
                                    <input
                                      type="text"
                                      {...register(`listing_title`)}
                                      // onChange={(e) =>
                                      //   handleNoteTitleChange(
                                      //     index,
                                      //     e.target.value
                                      //   )
                                      // }
                                      className="note-text mx-2"
                                      defaultValue={leadDetails.set_bids.listing_title}
                                    />
                                </div>

                                <div className="invalid-feedback">{errors?.listing_title?.message}</div>      

                                <div className="d-flex">
                                      <span>Description : </span>
                                    <textarea
                                      type="text"
                                      {...register(`listing_description`)}
                                      // onChange={(e) =>
                                      //   handleNoteContentChange(
                                      //     index,
                                      //     e.target.value
                                      //   )
                                      // }
                                      className="note-text mx-2" row="4" cols="40"
                                      defaultValue={leadDetails.set_bids.listing_description}
                                    />
                                </div>
                                <div className="invalid-feedback">{errors?.listing_description?.message}</div>          
                              </div>                              
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="form-group col-lg-12">
                      <div>
                        <label> Custom Fields </label>  
                        <Tooltip title="Add Custom">
                          <IconButton >
                            <FeatherIcon icon="plus" width="20" color="blue" onClick={() => { appendCustom({id:0, field_name:"",description:''}); setHide(true) }}/>
                          </IconButton>
                        </Tooltip>
                      </div>

                      <div>
                      {customFields.length > 0 ? (
                        <ul className="list-group">
                          {customFields.map((custom, index) => (
                            <li key={index} className="list-group-item">
                              <div className="note-content d-flex">
                                <span className="note-index mx-2">
                                  <b>{index + 1}.</b>
                                </span>
                                
                                <div className="border p-1">
                                  <div className="d-flex mb-2">
                                        <span>Title : </span>
                                      <input
                                        type="text"
                                        {...register(`leadCustomFieldsData.${index}.field_name`)}
                                        defaultValue={ custom.field_name }
                                        // onChange={(e) =>
                                        //   handleNoteTitleChange(
                                        //     index,
                                        //     e.target.value
                                        //   )
                                        // }
                                        className="note-text mx-2"
                                      />
                                  </div>

                                  {errors?.leadCustomFieldsData?.[index]?.field_name && <div className="invalid-feedback">{errors?.leadCustomFieldsData?.[index]?.field_name.message}</div>}      

                                  <div className="d-flex">
                                        <span>Field : </span>
                                      <textarea
                                        type="text"
                                        {...register(`leadCustomFieldsData.${index}.description`)}
                                        defaultValue={ custom.description }
                                        // onChange={(e) =>
                                        //   handleNoteContentChange(
                                        //     index,
                                        //     e.target.value
                                        //   )
                                        // }
                                        className="note-text mx-2" row="4" cols="40"
                                      />
                                  </div>
                                  {errors?.leadCustomFieldsData?.[index]?.description && <div className="invalid-feedback">{errors?.leadCustomFieldsData?.[index]?.description.message}</div>}          
                                </div>


                                <Tooltip title="Delete">
                                  {/* <IconButton onClick={() => { deleteNotes(note);}}> */}
                                  <IconButton onClick={() => { removeCustom(index);}}>
                                    <FeatherIcon icon="trash-2" width="18" color="red"/>
                                  </IconButton>
                                </Tooltip>
                                
                              </div>
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <>
                          <p style={{ fontSize: '12px' }}>No data</p> 
                        </>
                      )}
                      </div>
                    </div>

                    <div className="form-group col-lg-12">
                      <div>
                        <label> Notes </label>  
                        <Tooltip title="Add Note">
                          <IconButton >
                            <FeatherIcon icon="plus" width="20" color="blue" onClick={() => { appendNotes({id: 0,title:"",notes:'',is_admin_note : 1}); setHide(true) }}/>
                          </IconButton>
                        </Tooltip>
                      </div>

                      <div>
                          {notesFields.length > 0 ? (
                          <ul className="list-group">
                            {notesFields.map((note, index) => (
                              <li key={index} className="list-group-item">
                                <div className="note-content d-flex">
                                  <span className="note-index">
                                    <b>{index + 1}.</b>
                                  </span>
                                  
                                  <div className="border p-1">
                                    <div className="d-flex mb-2">
                                          <span>Title : </span>
                                        <input
                                          type="text"
                                          {...register(`leadNotesData.${index}.title`)}
                                          defaultValue={ note.title }
                                          // onChange={(e) =>
                                          //   handleNoteTitleChange(
                                          //     index,
                                          //     e.target.value
                                          //   )
                                          // }
                                          className="note-text mx-2"
                                        />
                                    </div>

                                    {errors?.leadNotesData?.[index]?.title && <div className="invalid-feedback">{errors?.leadNotesData?.[index]?.title.message}</div>}      

                                    <div className="d-flex">
                                          <span>Note : </span>
                                        <textarea
                                          type="text"
                                          {...register(`leadNotesData.${index}.notes`)}
                                          defaultValue={ note.notes }
                                          // onChange={(e) =>
                                          //   handleNoteContentChange(
                                          //     index,
                                          //     e.target.value
                                          //   )
                                          // }
                                          className="note-text mx-2" row="4" cols="40"
                                        />
                                    </div>
                                    {errors?.leadNotesData?.[index]?.notes && <div className="invalid-feedback">{errors?.leadNotesData?.[index]?.notes.message}</div>}          
                                  </div> 

                                  <Tooltip title="Delete">
                                    {/* <IconButton onClick={() => { deleteNotes(note);}}> */}
                                    <IconButton onClick={() => { removeNotes(index);}}>
                                      <FeatherIcon icon="trash-2" width="18" color="red"/>
                                    </IconButton>
                                  </Tooltip>
                                </div>
                              </li>
                            ))}
                          </ul>
                          ) : (
                            <>
                              <p style={{ fontSize: '12px' }}>No data</p> 
                            </>
                          )}
                      </div>
                    </div>

                    <div className="form-group col-lg-12">
                      <div> 
                      <label> Recordings </label>
                        <Tooltip title="Add Recording">
                          <IconButton >
                            <FeatherIcon icon="plus" width="20" color="blue" />
                            <input type="file" accept="audio/*" className="form-control"
                              multiple
                              onInput={recodingchnge}
                            />
                          </IconButton>
                        </Tooltip>
                      </div>
                      {selectedRecordings.length > 0 ? (
                        <div>
                          <ul className="list-group">
                            {selectedRecordings.map(
                              (recordings, index) => (
                                <li key={index} className="list-group-item">
                                  <div className="note-content">
                                    <div className="row">
                                      <span className="note-index">
                                        <b>{index + 1}.</b>
                                      </span>
                                      <audio
                                        src={
                                          updatedRecoredContent[recordings.id] ||
                                          `${process.env.REACT_APP_AWS_S3_URL}` + `${process.env.REACT_APP_LEAD_RECORDINGS}` + '/' + recordings.recordings_name
                                        }
                                        controls
                                        onPlay={(e) => handlePlay(e.target)}
                                      />
                                    </div>
                                    <input
                                      type="text"
                                      defaultValue={
                                        recordings.dummy_recording
                                      }
                                      onChange={(e) =>
                                        handleRecordingContentChange(
                                          index,
                                          e.target.value
                                        )
                                      }
                                      className="note-text mx-2"
                                    />
                                    <Tooltip title="Delete">
                                      <IconButton
                                        onClick={() => {
                                          // deleteRecordingIdWise(recordings.id);
                                          deleteRecordings(recordings)
                                        }}
                                      >
                                        <FeatherIcon
                                          icon="trash-2"
                                          width="18"
                                          color="red"
                                        />
                                      </IconButton>
                                    </Tooltip>
                                    <div>
                                      <div className="row">
                                        <div className="form-group col-lg-6">
                                          {/* <label> Virtual </label> */}
                                          <div className="form-control">
                                            <div className="form-check form-check-inline ">
                                              {/* {console.log('recordings.recording_show---: ', recordings.recording_show)} */}
                                              <input
                                                defaultChecked={
                                                  recordings.recording_show == "Visible" ? true : false
                                                }
                                                onChange={(e) =>
                                                  handleRecordingShowChange(
                                                    index,
                                                    e.target.value
                                                  )
                                                }
                                                className="form-check-input"
                                                type="radio"
                                                name={"recording_show_"+recordings.id}
                                                id="Visible"
                                                value={"Visible"}
                                              />
                                              <label className="form-check-label" htmlFor="Visible">
                                                {" "} Visible {" "}
                                              </label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                              <input
                                                defaultChecked={
                                                  recordings.recording_show == "Hidden" ? true :false 
                                                }
                                                onChange={(e) =>
                                                  handleRecordingShowChange(
                                                    index,
                                                    e.target.value
                                                  )
                                                }
                                                className="form-check-input"
                                                type="radio"
                                                name={"recording_show_"+recordings.id}
                                                id="Hidden"
                                                value={"Hidden"}
                                              />
                                              <label className="form-check-label" for="Hidden">
                                                {" "} Hidden {" "}
                                              </label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                              <input
                                                defaultChecked={
                                                  recordings.recording_show == "Visible at appt time" ? true : false 
                                                }
                                                onChange={(e) =>
                                                  handleRecordingShowChange(
                                                    index,
                                                    e.target.value
                                                  )
                                                }
                                                className="form-check-input"
                                                type="radio"
                                                name={"recording_show_"+recordings.id}
                                                id="VisibleApptTimeRecording"
                                                value={"Visible at appt time"}
                                              />
                                              <label className="form-check-label" for="VisibleApptTimeRecording">
                                                {" "} Visible at appt time {" "}
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              )
                            )}
                          </ul>
                        </div>
                      ) : (
                        <>
                          <p style={{ fontSize: '12px' }}>No data</p> 
                        </>
                      )}
                    </div>

                    {/* <div className="form-group col-lg-12">
                      <div> 
                        <label> Documents </label>

                        <Tooltip title="Add Document">
                          <IconButton >
                            <FeatherIcon icon="plus" width="20" color="blue" />
                            <input type="file" className="form-control"
                              accept=".pdf,.doc,.docx,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" 
                              multiple
                              onInput={docschnge}
                            />
                          </IconButton>
                        </Tooltip>
                      </div>
                        <div>
                        {selectedDocuments.length > 0 ? (
                          <ul className="list-group">
                            {selectedDocuments.map(
                              (documents, index) => (
                                <li key={index} className="list-group-item">
                                  <div className="note-content">
                                    <div className="row">
                                      <span className="note-index">
                                        <b>{index + 1}.</b>
                                      </span>
                                      <a
                                        href={
                                          updatedDocumentContent[documents.id] ||
                                          `${process.env.REACT_APP_AWS_S3_URL}` + `${process.env.REACT_APP_LEAD_DOCUMENTS}` + '/' + documents.documents
                                        }
                                        target="_blank"
                                        className="note-text mx-2"
                                      >
                                        {updatedDocumentContent[documents.id] ||
                                          documents.dummy_documents}
                                        </a>
                                    </div>
                                    <input
                                      type="text"
                                      defaultValue={
                                        documents.dummy_documents
                                      }
                                      onChange={(e) =>
                                        handleDocumentsContentChange(
                                          index,
                                          e.target.value
                                        )
                                      }
                                      className="note-text mx-2"
                                    />
                                    <Tooltip title="Delete">
                                      <IconButton
                                        onClick={() => {
                                          deleteDocuments(documents);
                                          // deleteDocumentsIdWise(documents.id);
                                        }}
                                      >
                                        <FeatherIcon
                                          icon="trash-2"
                                          width="18"
                                          color="red"
                                        />
                                      </IconButton>
                                    </Tooltip>
                                      <div>
                                        <div className="row">
                                          <div className="form-group col-lg-6">
                                            <div className="form-control">
                                              <div className="form-check form-check-inline ">
                                                {console.log('documents.document_show: ', documents.document_show)}
                                                <input
                                                  defaultChecked={
                                                    documents.document_show == "Visible" ? true :false
                                                  }
                                                  onChange={(e) =>
                                                    handleDocumentsShowChange(
                                                      index,
                                                      e.target.value
                                                    )
                                                  }
                                                  className="form-check-input"
                                                  type="radio"
                                                  name={"document_show_"+documents.id}
                                                  id="VisibleDocument"
                                                  value={"Visible"}
                                                />
                                                <label className="form-check-label" htmlFor="VisibleDocument">
                                                  {" "} Visible {" "}
                                                </label>
                                              </div>
                                              <div className="form-check form-check-inline">
                                                <input
                                                  defaultChecked={
                                                    documents.document_show == "Hidden" ? true :false
                                                  }
                                                  onChange={(e) =>
                                                    handleDocumentsShowChange(
                                                      index,
                                                      e.target.value
                                                    )
                                                  }
                                                  className="form-check-input"
                                                  type="radio"
                                                  name={"document_show_"+documents.id}
                                                  id="HiddenDocument"
                                                  value={"Hidden"}
                                                />
                                                <label className="form-check-label" for="HiddenDocument">
                                                  {" "} Hidden {" "}
                                                </label>
                                              </div>
                                              <div className="form-check form-check-inline">
                                                <input
                                                  defaultChecked={
                                                    documents.document_show == "Visible at appt time" ? true :false
                                                  }
                                                  onChange={(e) =>
                                                    handleDocumentsShowChange(
                                                      index,
                                                      e.target.value
                                                    )
                                                  }
                                                  className="form-check-input"
                                                  type="radio"
                                                  name={"document_show_"+documents.id}
                                                  id="VisibleApptTimeDocument"
                                                  value={"Visible at appt time"}
                                                />
                                                <label className="form-check-label" for="VisibleApptTimeDocument">
                                                  {" "} Visible at appt time {" "}
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                  </div>
                                </li>
                              )
                            )}
                          </ul>
                          ) : (
                            <>
                              <p style={{ fontSize: '12px' }}>No data</p> 
                            </>
                          )}
                        </div>
                    </div> */}

                    <div className="form-group col-lg-12">
                      <div > 
                        <label> Images </label>

                        <Tooltip title="Add Images">
                          <IconButton >
                            <FeatherIcon icon="plus" width="20" color="blue" />
                            <input
                              type="file"
                              className="form-control"
                              accept="image/*,.gif" 
                              onInput={imgchnge} 
                              multiple
                            />
                          </IconButton>
                        </Tooltip>
                      </div>
                      {selectedImages.length > 0 ? (
                        <div>
                          <ul className="list-group">
                            <div class="row">
                              {selectedImages.map((img, index) => (
                                <div className="col-2" key={img}>
                                  <li
                                    key={index}
                                    className="list-group-item custom-list-item"
                                  >
                                    <div className="note-content">
                                      <div className="row" style={{ position: 'relative', display: 'inline-block' }}>
                                        <React.Fragment>
                                          <Image
                                            className="m-1 shadow-sm"
                                            style={{ width: 150, height: 150, cursor:'pointer' }}
                                            src={`${process.env.REACT_APP_AWS_S3_URL}` + `${process.env.REACT_APP_LEAD_IMAGES}` + '/' + img}
                                            onClick={()=>handleImageClick(`${process.env.REACT_APP_AWS_S3_URL}` + `${process.env.REACT_APP_LEAD_IMAGES}` + '/' + img)}
                                            rounded
                                          />
                                          {/* <Tooltip title="Delete">
                                            <IconButton
                                              className="fa-stack the-wrapper icon-tag"
                                              onClick={() => {
                                                // deleteImagessIdWise(img.id);
                                                deleteImage(img);
                                              }}
                                            >
                                              <FeatherIcon
                                                icon="x"
                                                width="18"
                                                color="red"
                                              />
                                            </IconButton>
                                          </Tooltip> */}

                                          <Tooltip title="Delete" placement="top">
                                            <IconButton style={{ position: 'absolute', top: 0, right: 30 }} className="fa-stack the-wrapper icon-tag" onClick={() => {deleteImage(img)}}>
                                                {/* <FeatherIcon icon="x" width="18" color="red" /> */}
                                                <img src={process.env.PUBLIC_URL + "/assets/images/cancel.png"} height={"20px"} width={"20px"} />
                                            </IconButton>
                                        </Tooltip>

                                        <Tooltip title="Download" placement="top">
                                            <IconButton style={{ position: 'absolute', top: 0, right: 0 }} className="fa-stack the-wrapper icon-tag" onClick={() => handleImageDownload(img)}>
                                                <FeatherIcon icon="download" width="18" color="blue" />
                                            </IconButton>
                                        </Tooltip>
                                        </React.Fragment>
                                      </div>
                                    </div>
                                  </li>
                                </div>
                              ))}
                            </div>
                          </ul>
                        </div>
                      ) : (
                        <>
                         <p style={{ fontSize: '12px' }}>No data</p> 
                        </>
                     )}
                     {/* {files?.length > 0 ? (
                        <div>
                          <ul className="list-group">
                            <div class="row">
                              {files.map((img, index) => (
                                <div className="col-3">
                                  <li
                                    key={index}
                                    className="list-group-item custom-list-item"
                                  >
                                    <div className="note-content">
                                      <div className="row ">
                                        <React.Fragment>
                                          <Image
                                            className="m-2 shadow-sm"
                                            style={{ width: 150, height: 150 }}
                                            src={
                                              img
                                                ? URL.createObjectURL(img)
                                                : "https://www.pngitem.com/pimgs/m/508-5087336_person-man-user-account-profile-employee-profile-template.png"
                                            }
                                            rounded
                                          />
                                          <Tooltip title="Delete">
                                            <IconButton
                                              className="fa-stack the-wrapper icon-tag"
                                              onClick={() => {
                                                // deleteImagessIdWise(img.id);
                                                deleteImage(index);
                                              }}
                                            >
                                              <FeatherIcon
                                                icon="x"
                                                width="18"
                                                color="red"
                                              />
                                            </IconButton>
                                          </Tooltip>
                                        </React.Fragment>
                                      </div>
                                    </div>
                                  </li>
                                </div>
                              ))}
                            </div>
                          </ul>
                        </div>
                      ) : (
                        <> 
                        </>
                     )} */}
                    </div>

                    <div className="form-group justify-content-center text-center">
                      <button
                        type="submit"
                        className="btn btn-primary waves-effect waves-light m-2"
                      >
                        Update
                      </button>
                      <button className="btn btn-danger" onClick={redirect}>
                        {" "}
                        Cancel{" "}
                      </button>
                    </div>
                  </form>
                }

                {/* <br /><br /> */}
                {/* </div> */}
                {/* <Link to='/leadsList' className="btn btn-primary btn-lg common_hover">
                                        <i className="mdi mdi-chevron-left"></i> <span> back </span>
                                    </Link> */}
              </div>
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Body>
            <img src={modalImageSrc} alt="Full Screen" className="img-fluid" />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default InReviewLeadEdit;