import React, { useEffect, useRef, useState } from "react";
import {addRecentSoldLead, getIndustrylist } from "../../api/apiHandler";
import $ from "jquery";
import FeatherIcon from "feather-icons-react";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Swal from "sweetalert2";
import { renderToStaticMarkup } from "react-dom/server";
import { Await, Link, useNavigate, useParams } from "react-router-dom";
import { bodyDecryption } from "../common/RedirectPathMange";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useFieldArray, useForm } from "react-hook-form";
import { Form, Image } from "react-bootstrap";
import Select from "react-select";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import S3 from 'react-aws-s3';
import AWS from "../../AWS"
import { useDropzone } from "react-dropzone";
import moment from 'moment';
import { Modal } from 'react-bootstrap';

window.Buffer = window.Buffer || require("buffer").Buffer;

const S3_BUCKET = process.env.REACT_APP_BUCKET_NAME;
const REGION = process.env.REACT_APP_REGION;
const ACCESS_KEY = process.env.REACT_APP_AWS_ACCESS_KEY_ID;
const SECRET_ACCESS_KEY = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;

const config = {
  bucketName: S3_BUCKET,
  dirName: process.env.REACT_APP_LEAD_IMAGES,
  region: REGION,
  accessKeyId: ACCESS_KEY,
  secretAccessKey: SECRET_ACCESS_KEY,
  s3Url: process.env.REACT_APP_AWS_S3_URL,
};

const configRec = {
  bucketName: S3_BUCKET,
  dirName: process.env.REACT_APP_LEAD_RECORDINGS,
  region: REGION,
  accessKeyId: ACCESS_KEY,
  secretAccessKey: SECRET_ACCESS_KEY,
  s3Url: process.env.REACT_APP_AWS_S3_URL,
};

const ReactS3ClientImg = new S3(config);
const ReactS3ClientRec = new S3(configRec);

function AddRecentSoldLead() {
  
  const navigate = useNavigate();

  const [pageNo, setPageNo] = useState(1);
  const [industrysList, setIndustryList] = useState([]);

  const [phoneNumber, setPhoneNumber] = useState("");
  const [secondaryPhoneNumber, setSecondaryPhoneNumber] = useState("");
  const [isVirtual, setIsVirtual] = useState(1);
  const [isActive, setIsActive] = useState(1);
  const [isShowContactInfo, setIsShowContactInfo] = useState(1);

  const [address, setAddress] = useState("");
  const [coordinates, setCoordinates] = useState(null);
  const [loader, setLoader] = useState(false);

  const [updatedRecoredContent, setUpdatedRecoredContent] = useState({});
  const [selectedDisposition, setSelectedDisposition] = useState("");
  
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedRecordings, setSelectedRecordings] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [modalImageSrc, setModalImageSrc] = useState("");

  const [imge, setImge] = useState([]);
  const [upd, setUpd] = useState([]);

  const [recording, setRecording] = useState([]);
  const [updRecording, setUpdRecording] = useState([]);

  const [hide, setHide] = useState(false);

  const [currentAudio, setCurrentAudio] = useState(null);
  
  useEffect(() => {
    getIndustrylist({
      page: pageNo,
      record_count: parseInt(process.env.REACT_APP_RECORD_COUNT),
    }).then((response) => {
      response = JSON.parse(response);
      
      if (response.code === 200) {
        setIndustryList(response?.data?.Industry);
        // console.log("object",response?.data?.Industry)
        // when multiple
        // setOptions((prevOptions) => {
        //   return response.data.Industry.map((industry) => ({
        //     value: industry.id,
        //     label: industry.industry_name,
        //   }));
        // });
        // setValue("industry", response.data.Industry);

        //
      } else {
        setIndustryList([]);
      }
    });
    setValue("category","Verified")
  }, []);

  const validationSchema = Yup.object().shape({
    // industry: Yup.array().min(1).required("Please Select Industry"),
    // industry: Yup.string().required("Please Select Industry"),
    // category: Yup.string().required("Please select category"),
    // name: Yup.string().required("Please enter name"),
    email: Yup.string()
      .required("Please enter email address")
      .email("Please enter valid email address"),
    phone_number: Yup.string()
      .required("Please enter phone number")
      .matches(/^\(\d{3}\)\s\d{3}-\d{4}$/, 'Please enter valid phone number'),
    // secondary_phone_number: Yup.string()
    //   .required("Please enter secondary phone number")
    //   .matches(/^\(\d{3}\)\s\d{3}-\d{4}$/, 'Please enter valid secondary phone number'),
    // address: Yup.string().required("Please enter address"),
    // latitude: Yup.string().required("Please enter latitude"),
    // longitude: Yup.string().required("Please enter longitude"),
    // appointment_date: Yup.string().required("Please select appointment date"),
    // status: Yup.string().required("Please select status"),
    // badges: Yup.string().required("Please select badges"),
    // disposition: Yup.string().required("Please select disposition"),
    // disposition_note: Yup.string().required("Please select disposition note"),
    // dispos_resch_date : selectedDisposition === 'Rescheduled'? Yup.string().required('Please select Rescheduled Date & time'): Yup.string(),
    // dispos_resch_time: selectedDisposition === 'Reschedule'? Yup.string().required('Please select reschedule time'): Yup.string(),
    won_date: Yup.string().required("Please select sold date"),
    sold_amount: Yup.number().typeError("Please enter sold price").required("Please enter sold price"),
    listing_title: Yup.string().required("Please enter lead title"),
    listing_description: Yup.string().required("Please enter lead description"),
    leadNotesData: Yup.array()
            .of(
                Yup.object().shape({
                    // _id: Yup.string(),
                    // title: Yup.string().required('Title is required'),
                    notes: Yup.string().required('Notes is required'),
                })
            ),
            // ).max(5, 'You can add a maximum of 5 notes'),
    leadCustomFieldsData: Yup.array()
            .of(
                Yup.object().shape({
                    // _id: Yup.string(),
                    // field_name: Yup.string().required('Title is required'),
                    description: Yup.string().required('Field is required'),
                })
            ),
      // admin_commission: Yup.number()
      //   .transform((value, originalValue) => {
      //     // If original value is an empty string, return null
      //     return originalValue.trim() === "" ? null : value;
      //   })
      //   .nullable() // Allow null values after transformation
      //   .required("Admin commission is required")
      //   .min(1, "Admin commission must be at least 1")
      //   .max(100, "Admin commission cannot be more than 100")
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, formState, setValue, setError, watch, control } =
    useForm(formOptions);
  const { errors } = formState;
  watch(formOptions);

  const { fields: notesFields, append: appendNotes, remove: removeNotes } = useFieldArray({
    control,
    name: "leadNotesData"
  });

  const { fields: customFields, append: appendCustom, remove: removeCustom } = useFieldArray({
    control,
    name: "leadCustomFieldsData"
  });

  function redirect() {
    navigate("/leadsList");
  }

  const handleSelect = async (value) => {
    try {
      const results = await geocodeByAddress(value);
      const latlong = await getLatLng(results[0]);
      //
      //
      setValue("address", results[0].formatted_address);
      setValue("latitude", latlong.lat);
      setValue("longitude", latlong.lng);
      setAddress(value);
      setCoordinates(latlong);
    } catch (error) {}
  };

  const handleDispositionChange = (e) => {
    setSelectedDisposition(e.target.value);
  };

  const recodingchnge = async (e) => {
    setRecording([])
    setUpdRecording([])
    var abc = []
    var length =  0
    const files = Array.from(e.target.files);
    recording.push(files)
    setLoader(true);

    recording[0].forEach((file) => {
        const ext = file.name.split('.').pop()
        // var newfile = new Date().getTime() + file.name
        const newfile = new Date().getTime() + '.'+ext;
        ReactS3ClientRec.uploadFile(file, newfile).then(data => {
            if (data.status === 204) {
                ++length
                const recDatas = {
                  "recordings_name" : data.location.split("/")[5],
                  "dummy_recording" : data.location.split("/")[5]
                }
                abc.push(recDatas);
                if(length == e.target.files.length){
                  setUpdRecording(abc)
                  setLoader(false);
                }
                setLoader(false);
            } else {
                setLoader(false);
                console.log('obj err')
            }
        });
    });
  }

  const handleCloseModal = () => {
    setShowModal(false);
    setModalImageSrc("");
  };

  function handleImageDownload (image) {
    let img_url = `${process.env.REACT_APP_AWS_S3_URL}` + `${process.env.REACT_APP_LEAD_IMAGES}` + '/' + image;
    console.log('img_url: ', img_url);
    window.open(img_url, '_blank');
  }

  const deleteImage = async (img) => {
    setLoader(true);
    let index = selectedImages.indexOf(img);
    selectedImages.splice(index, 1);
    setSelectedImages([])
    setTimeout(() => {
      setSelectedImages(selectedImages)
      setLoader(false);
    }, 500);
    // const updatedFiles = [...files];
    // updatedFiles.splice(index, 1);
    // setFiles(updatedFiles);

    // // Get the unique identifier of the image to remove
    // const imageIdToRemove = leadDetails.lead_images[index].id;

    // // Remove the corresponding image from lead_images in leadDetails
    // const updatedLeadDetails = { ...leadDetails };
    // updatedLeadDetails.lead_images = updatedLeadDetails.lead_images.filter(image => image.id !== imageIdToRemove);
    // setLeadDetails(updatedLeadDetails);
  };

  const handleImageClick = (imageSrc) => {
    setModalImageSrc(imageSrc);
    setShowModal(true);
  };

  const imgchnge = async (e) => {
    try{
      setImge([])
      setUpd([])
      var abc = []
      var length =  0
      const files = Array.from(e.target.files);
      imge.push(files)
      setLoader(true);

      imge[0].forEach((file) => {
        const ext = file.name.split('.').pop()
        // var newfile = new Date().getTime() + file.name
        const newfile = new Date().getTime() + '.'+ext;
        ReactS3ClientImg.uploadFile(file, newfile).then(data => {
          if (data.status === 204) {
                  ++length
                  abc.push(data.location.split("/")[5]);
                  if(length == e.target.files.length){

                    setUpd(abc)
                    setLoader(false);
                  }
                  setLoader(false);
              } else {
                  setLoader(false);
                  console.log('obj err')
              }
        });
      });
    } catch (error){
      console.log(error,"error....");
    }
  }

  const handleRecordingContentChange = (index, newRecordContent) => {
    selectedRecordings[index].dummy_recording = newRecordContent
    setSelectedRecordings(selectedRecordings);
  };

  const handleRecordingShowChange = (index, newRecordShowContent) => {
    selectedRecordings[index].recording_show = newRecordShowContent
    setSelectedRecordings(selectedRecordings);
  };

  const handlePlay = (audioElement) => {
    if (currentAudio && currentAudio !== audioElement) {
        currentAudio.pause();
    }
    setCurrentAudio(audioElement);
  };

  const deleteRecordings = async (recordings) => {
    setLoader(true);
    let index = selectedRecordings.indexOf(recordings);
    selectedRecordings.splice(index, 1);
    setSelectedRecordings([])
    setTimeout(() => {
      setSelectedRecordings(selectedRecordings)
      setLoader(false);
    }, 500);
  };

  const onSubmit = async (data) => {
    data.appointment_date = moment(data.appointment_date).utc().format('YYYY-MM-DD HH:mm:ss');
    data.won_date = moment(data.won_date).utc().format('YYYY-MM-DD HH:mm:ss');
    data.name =  data.name.trimStart().trimEnd()
    data.email=  data.email.trimStart().trimEnd()
    data.phone_number = phoneNumber.trimStart().trimEnd()
    data.secondary_phone_number= secondaryPhoneNumber.trimStart().trimEnd()
    // data.phone_number = phoneNumber
    // data.secondary_phone_number = secondaryPhoneNumber
    data.is_virtual = isVirtual
    data.is_active = isActive
    data.is_showcontactinfo = isShowContactInfo
    data.images = selectedImages
    data.recordings_name = selectedRecordings
    console.log('data: ', data);

    addRecentSoldLead(data).then((response) => {
      response = JSON.parse(response);
      if (response.code == 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: response.message,
          toast: true,
          showConfirmButton: false,
          timer: 2000,
        });
        setTimeout(() => {
          navigate("/leadsList");
        }, 2000);
      } else {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: response.message,
          toast: true,
          showConfirmButton: false,
          timer: 2000,
        });
      }
    });
  }

  const handleInputChange = (e) => {
    const input = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    const formattedPhoneNumber = formatPhoneNumber(input);
    setPhoneNumber(formattedPhoneNumber);
  };

  const handleInputChange2 = (e) => {
    const input = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    const formattedPhoneNumber = formatPhoneNumber(input);
    setSecondaryPhoneNumber(formattedPhoneNumber);
  };

  const formatPhoneNumber = (value) => {
    if (!value) return value;

    // Format the input based on length
    const phoneNumberLength = value.length;

    if (phoneNumberLength < 4) return value;
    if (phoneNumberLength < 7) {
      return `(${value.slice(0, 3)}) ${value.slice(3)}`;
    }
    return `(${value.slice(0, 3)}) ${value.slice(3, 6)}-${value.slice(6, 10)}${value.slice(10)}`;
  };


  const handleChange = (event) => {
    if(event.target.name=='is_virtual'){
      setIsVirtual(event.target.value);
    }
    if(event.target.name=='is_active'){
      setIsActive(event.target.value);
    }
    if(event.target.name=='is_showcontactinfo'){
      setIsShowContactInfo(event.target.value);
    }
  };

  useEffect(() => {
    if(loader){
      Swal.fire({
      title: 'Please wait...',
      didOpen: () => {
          Swal.showLoading();
      }
    })
    } else {
      Swal.close();
    }
  }, [loader]);

  useEffect(() => {
    if(upd.length > 0){
      setSelectedImages(selectedImages.concat(upd))
    }
  }, [upd]);

  useEffect(() => {
    if(updRecording.length > 0){
      updRecording[0].recording_show = "Visible";
      setSelectedRecordings(selectedRecordings.concat(updRecording))
    }
  }, [updRecording]);

  if (loader) return <loader/>;
  
  return (
    <>
      <div className="content-page mt-2">
        <div className="content">
          <div className="container-fluid">
            <div className="cal">
              {/* <div className="row clearfix d-flex justify-content-center text-center" > */}
              <div className="col-lg-12 col-md-12">
                <h2 className="text-center mb-3">Create Lead</h2>
                {/* <div className="body"> */}
                {
                  <form onSubmit={handleSubmit(onSubmit)}> 
                    <div className="row">
                      
                        <>
                          <div className="form-group col-lg-6">
                          <label className=""> Industry </label>
                            <div>
                              <select
                                aria-label="Default select example"
                                className="form-control"
                                {...register("industry")}
                              >
                                <option value="">Open this select menu</option>
                                {industrysList?.map((industry, index) => {
                                  return (
                                    <option
                                      value={industry?.industry_name}
                                      key={index}
                                    >
                                      {industry?.industry_name}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                        </>

                      <div className="form-group col-lg-6">
                        <label className="required"> Category </label>
                        <div>
                          <Form.Select
                            aria-label="Default select example"
                            className="form-control"
                            {...register("category")}
                            disabled
                            >
                                                          
                            <option value="">Open this select menu</option>
                            <option value="In review">In review</option>
                            <option value="To be verified">Pending</option>
                            <option value="Verified">Approved</option>
                          </Form.Select>
                          {/* <div className="invalid-feedback">
                            {errors.category?.message}
                          </div> */}
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="form-group col-lg-6">
                        <label className=""> Name </label>
                        <div>
                          <input
                            type="text"
                            {...register("name")}
                            className="form-control"
                            placeholder="Please Enter Name"
                          />
                          <div className="invalid-feedback">
                            {errors.name?.message}
                          </div>
                        </div>
                      </div>
                      <div className="form-group col-lg-6">
                        <label className="required"> Email </label>
                        <div>
                          <input
                            type="text"
                            {...register("email")}
                            className="form-control"
                            placeholder="Please Enter Email"
                          />
                          <div className="invalid-feedback">
                            {errors.email?.message}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="form-group col-lg-6">
                        <label className="required"> Phone Number </label>
                        <div>
                          <input
                            type="text"
                            {...register("phone_number")}
                            // className={`form-control ${leadDetails.is_showcontactinfo == 0 ? "blurred" : ""}`}
                            value={phoneNumber}
                            onChange={handleInputChange}
                            className="form-control"
                            placeholder="Please Enter Phone Number"
                          />
                          <div className="invalid-feedback">
                            {errors.phone_number?.message}
                          </div>
                        </div>
                      </div>
                      <div className="form-group col-lg-6">
                        <label className="">
                          {" "}
                          Secondary Phone Number{" "}
                        </label>
                        <div>
                          <input
                            type="text"
                            {...register("secondary_phone_number")}
                            // className={`form-control ${leadDetails.is_showcontactinfo == 0 ? "blurred" : ""}`}
                            value={secondaryPhoneNumber}
                            onChange={handleInputChange2}
                            className="form-control"
                            placeholder="Please Enter Secondary Phone Number"
                          />
                          {/* <div className="invalid-feedback">
                            {errors.secondary_phone_number?.message}
                          </div> */}
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="form-group col-lg-12">
                        <label> Address </label>
                        <div>
                          <PlacesAutocomplete
                            value={address}
                            onChange={setAddress}
                            onSelect={handleSelect}
                          >
                            {({
                              getInputProps,
                              suggestions,
                              getSuggestionItemProps,
                              loading,
                            }) => (
                              <div>
                                <input
                                  {...getInputProps({
                                    placeholder: "Enter address...",
                                    className: "form-control",
                                  })}
                                />
                                <div>
                                  {loading ? <div>Loading...</div> : null}

                                  {suggestions.map((suggestion) => {
                                    const style = {
                                      backgroundColor: suggestion.active
                                        ? "#41b6e6"
                                        : "#fff",
                                    };
                                    return (
                                      <div
                                        {...getSuggestionItemProps(suggestion, {
                                          style,
                                        })}
                                      >
                                        {suggestion.description}
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            )}
                          </PlacesAutocomplete>
                          <div className="invalid-feedback">
                            {errors.address?.message}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="form-group col-lg-6">
                        <label> Appointment Date & Time </label>
                        <div>
                          {/* <p style={{ fontSize: '15px' }}>{ moment(leadDetails.createdAt).local().format('YYYY-MM-DD hh:mm A') }</p> */}
                          <input
                            type="datetime-local"
                            {...register("appointment_date")}
                            className="form-control"
                            // defaultValue={localAppointmentDate}
                            placeholder="Please Enter Appointment Date"
                          />
                          <div className="invalid-feedback">
                            {errors.appointment_date?.message}
                          </div>
                        </div>
                      </div>
                      <div className="form-group col-lg-6">
                        <label> Virtual </label>
                        <div className="form-control">
                          <div className="form-check form-check-inline ">
                            <input
                              {...register("is_virtual")}
                              className="form-check-input"
                              type="radio"
                              name="is_virtual"
                              id="yes"
                              value={1}
                              checked={isVirtual == 1}
                              onChange={handleChange}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="inlineRadio1"
                            >
                              Yes
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              {...register("is_virtual")}
                              className="form-check-input"
                              type="radio"
                              name="is_virtual"
                              id="no"
                              value={0}
                              checked={isVirtual == 0}
                              onChange={handleChange}
                            />
                            <label
                              className="form-check-label"
                              for="inlineRadio2"
                            >
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="form-group col-lg-6">
                        <label> Lead Price </label>
                        <div>
                          <input
                            type="number"
                            {...register("lead_price")}
                            className="form-control"
                            // defaultValue={}
                            placeholder="Please Enter Lead Price"
                          />  
                        </div>
                      </div>
                      <div className="form-group col-lg-6">
                        <label> Status </label>
                        <div>
                          {/* <input type="text" {...register('status')} className="form-control" defaultValue={leadDetails.status} placeholder="Please Enter S" 
                                                            /> */}
                          <Form.Select
                            aria-label="Default select example"
                            className="form-control"
                            {...register("status")}
                            onChange={handleDispositionChange}
                          >
                            <option value="">Open this select menu</option>
                            <option value="Bad Credit">Bad Credit</option>
                            <option value="Cancelled">Cancelled</option>
                            <option value="Disqualified">Disqualified</option>
                            <option value="Has Solar">Has Solar</option>
                            <option value="Homeowner Not Home 1">Homeowner Not Home 1</option>
                            <option value="Homeowner Not Home 2">Homeowner Not Home 2</option>
                            <option value="Homeowner Not Home 3">Homeowner Not Home 3</option>
                            <option value="In-Contact">In-Contact</option>
                            <option value="Moving">Moving</option>
                            <option value="New Lead">New Lead</option>
                            <option value="No Interest">No Interest</option>
                            <option value="No Response">No Response</option>
                            <option value="No Show">No Show</option> 
                            <option value="Non-Decision-Maker">Non-Decision-Maker</option>
                            <option value="Renter">Renter</option>
                            <option value="Rescheduled">Rescheduled</option>
                            <option value="Shading">Shading</option>
                            <option value="Spanish Speaker">Spanish Speaker</option>
                            <option value="Under Contract">Under Contract</option>
                            
                            {/*                             
                            <option value="Live">Live</option>
                            <option value="Pending">Pending</option>
                            <option value="Held">Held</option>
                            <option value="Missed">Missed</option>
                            */}

                            {/* --------- */}
                            {/* <option value="Live">Live</option>
                            <option value="Pending">Pending</option>
                            <option value="Held">Held</option>
                            <option value="Rescheduled">Rescheduled</option>
                            <option value="Missed">Missed</option>
                            <option value="No Show">No Show</option>
                            <option value="Credit/Insurance Fail">
                              Credit/Insurance Fail
                            </option>
                          <option value="Unsold">Unsold</option>
                          <option value="In-Review">In-Review</option> */}
                            
                            
                            
                          </Form.Select>
                          <div className="invalid-feedback">
                            {errors.status?.message}
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* {selectedDisposition === "Rescheduled" && (
                      <div className="row">
                        <div className="form-group col-lg-12">
                          <label className="required"> Reschdule Date & Time </label>
                          <div>
                            <input
                              type="datetime-local"
                              {...register("dispos_resch_date")}
                              className="form-control"
                              // defaultValue={ localAppointmentDate }
                              defaultValue={ moment.utc(leadDetails.dispos_resch_date).local().format('YYYY-MM-DD HH:mm:ss') }
                            />
                            <div className="invalid-feedback">
                              {errors.dispos_resch_date?.message}
                            </div>
                          </div>
                        </div>
                      </div>
                    )} */}

                    <div className="row">
                      <div className="form-group col-lg-6">
                        <label> Admin Commission % ( default commission % )</label>
                        <div>
                          <input
                            type="number"
                            {...register("admin_commission")}
                            className="form-control"
                            placeholder="Please Enter Commission"
                          />
                        </div>
                        <div className="invalid-feedback">
                          {errors.admin_commission?.message}
                        </div>
                      </div>
                      <div className="form-group col-lg-6">
                        <label> Badges </label>
                        <div>
                          <Form.Select
                            aria-label="Default select example"
                            className="form-control"
                            {...register("badges")}
                          >
                            <option value="">Open this select menu</option>
                            <option value="Not-verified">Not-verified</option>
                            <option value="Verified">Verified</option>
                            <option value="Multi-Post">Multi-Post</option>
                            <option value="Re-Post">Re-Post</option>
                          </Form.Select>
                          <div className="invalid-feedback">
                            {errors.badges?.message}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="form-group col-lg-6">
                        <label> Active/Inactive </label>
                        <div className="form-control">
                          <div className="form-check form-check-inline ">
                            <input
                              {...register("is_active")}
                              className="form-check-input"
                              type="radio"
                              name="is_active"
                              id="Active"
                              value={1}
                              checked={isActive == 1}
                              onChange={handleChange}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="inlineRadio1"
                            >
                              Active
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              {...register("is_active")}
                              className="form-check-input"
                              type="radio"
                              name="is_active"
                              id="Inactive"
                              value={0}
                              checked={isActive == 0}
                              onChange={handleChange}
                            />
                            <label
                              className="form-check-label"
                              for="inlineRadio2"
                            >
                              Inactive
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="form-group col-lg-6">
                        <label> Show Contact Info </label>
                        <div className="form-control">
                          <div className="form-check form-check-inline ">
                            <input
                              {...register("is_showcontactinfo")}
                              className="form-check-input"
                              type="radio"
                              name="is_showcontactinfo"
                              id="is_showcontactinfo_hide"
                              value={1}
                              checked={isShowContactInfo == 1}
                              onChange={handleChange}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="inlineRadio1"
                            >
                              Yes
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              {...register("is_showcontactinfo")}
                              className="form-check-input"
                              type="radio"
                              name="is_showcontactinfo"
                              id="is_showcontactinfo_show"
                              value={0}
                              checked={isShowContactInfo == 0}
                              onChange={handleChange}
                            />
                            <label
                              className="form-check-label"
                              for="inlineRadio2"
                            >
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="form-group col-lg-6">
                        {/* <label className=""> Disposition </label>
                        <div>
                          <Form.Select
                            aria-label="Default select example"
                            className="form-control"
                            {...register("disposition")}
                            defaultValue={leadDetails.disposition}
                            onChange={handleDispositionChange}
                          >
                            <option value="">Open this select menu</option>
                            <option value="Update">Update</option>
                            <option value="Cancel">Cancel</option>
                            <option value="Rescheduled">Rescheduled</option>
                          </Form.Select> */}
                          {/* <div className="invalid-feedback">
                            {errors.disposition?.message}
                          </div> */}
                        {/* </div>
                      </div> */}
                    </div>

                    {/* <div className="row">
                      <div className="form-group col-lg-12">
                        <label> Disposition Note </label>
                        <div>
                          <textarea
                            rows="3"
                            {...register("disposition_note")}
                            className="form-control"
                            defaultValue={leadDetails.disposition_note}
                            placeholder="Please Enter Disposition Note"
                          />
                        </div> */}
                        {/* <div className="invalid-feedback">
                          {errors.disposition_note?.message}
                        </div> */}
                      {/* </div> */}
                    </div>

                    <div className="row">
                      <div className="form-group col-lg-6">
                        <label className="required"> Sold Date </label>
                        <div>
                          {/* <p style={{ fontSize: '15px' }}>{ moment(leadDetails.createdAt).local().format('YYYY-MM-DD hh:mm A') }</p> */}
                          <input
                            type="datetime-local"
                            {...register("won_date")}
                            className="form-control"
                            // defaultValue={localAppointmentDate}
                          />
                          <div className="invalid-feedback">
                            {errors.won_date?.message}
                          </div>
                        </div>
                      </div>
                      <div className="form-group col-lg-6">
                        <label className="required"> Sold Price </label>
                        <div>
                          <input
                            type="number"
                            {...register("sold_amount")}
                            className="form-control"
                            // defaultValue={}
                            placeholder="Please Enter Sold Price"
                          />
                          <div className="invalid-feedback">
                            {errors.sold_amount?.message}
                          </div>  
                        </div>
                      </div>
                    </div>

                    <div className="form-group col-lg-12">
                      <div>
                        <label> Notes </label>  
                        <Tooltip title="Add Note">
                          <IconButton >
                            <FeatherIcon icon="plus" width="20" color="blue" onClick={() => { appendNotes({id:0, title:"",notes:'',is_admin_note : 1}); setHide(true) }}/>
                          </IconButton>
                        </Tooltip>
                      </div>

                      <div>
                      {notesFields.length > 0 ? (
                        <ul className="list-group">
                          {notesFields.map((note, index) => (
                            <li key={index} className="list-group-item">
                              <div className="note-content d-flex">
                                <span className="note-index mx-2">
                                  <b>{index + 1}.</b>
                                </span>
                                
                                <div className="border p-1">
                                  <div className="d-flex mb-2">
                                        <span>Title : </span>
                                      <input
                                        type="text"
                                        {...register(`leadNotesData.${index}.title`)}
                                        defaultValue={ note.title }
                                        // onChange={(e) =>
                                        //   handleNoteTitleChange(
                                        //     index,
                                        //     e.target.value
                                        //   )
                                        // }
                                        className="note-text mx-2"
                                      />
                                  </div>

                                  {errors?.leadNotesData?.[index]?.title && <div className="invalid-feedback">{errors?.leadNotesData?.[index]?.title.message}</div>}      

                                  <div className="d-flex">
                                        <span>Note : </span>
                                      <textarea
                                        type="text"
                                        {...register(`leadNotesData.${index}.notes`)}
                                        defaultValue={ note.notes }
                                        // onChange={(e) =>
                                        //   handleNoteContentChange(
                                        //     index,
                                        //     e.target.value
                                        //   )
                                        // }
                                        className="note-text mx-2" row="4" cols="40"
                                      />
                                  </div>
                                  {errors?.leadNotesData?.[index]?.notes && <div className="invalid-feedback">{errors?.leadNotesData?.[index]?.notes.message}</div>}          
                                </div>


                                <Tooltip title="Delete">
                                  {/* <IconButton onClick={() => { deleteNotes(note);}}> */}
                                  <IconButton onClick={() => { removeNotes(note);}}>
                                    <FeatherIcon icon="trash-2" width="18" color="red"/>
                                  </IconButton>
                                </Tooltip>
                                
                              </div>
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <>
                          <p style={{ fontSize: '12px' }}>No data</p> 
                        </>
                      )}
                      </div>
                    </div>

                    <div className="form-group col-lg-12">
                      <div>
                        <label> Title & Description </label>  
                      </div>

                      <div>
                        <ul className="list-group">
                          <li className="list-group-item">
                            <div className="note-content d-flex">
                              <div className="border p-1">
                                <div className="d-flex mb-2">
                                      <span>Title : </span>
                                    <input
                                      type="text"
                                      {...register(`listing_title`)}
                                      // onChange={(e) =>
                                      //   handleNoteTitleChange(
                                      //     index,
                                      //     e.target.value
                                      //   )
                                      // }
                                      className="note-text mx-2"
                                    />
                                </div>

                                <div className="invalid-feedback">{errors?.listing_title?.message}</div>      

                                <div className="d-flex">
                                      <span>Description : </span>
                                    <textarea
                                      type="text"
                                      {...register(`listing_description`)}
                                      // onChange={(e) =>
                                      //   handleNoteContentChange(
                                      //     index,
                                      //     e.target.value
                                      //   )
                                      // }
                                      className="note-text mx-2" row="4" cols="40"
                                    />
                                </div>
                                <div className="invalid-feedback">{errors?.listing_description?.message}</div>          
                              </div>                              
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="form-group col-lg-12">
                      <div>
                        <label> Custom Fields </label>  
                        <Tooltip title="Add Custom">
                          <IconButton >
                            <FeatherIcon icon="plus" width="20" color="blue" onClick={() => { appendCustom({id:0, field_name:"",description:''}); setHide(true) }}/>
                          </IconButton>
                        </Tooltip>
                      </div>

                      <div>
                      {customFields.length > 0 ? (
                        <ul className="list-group">
                          {customFields.map((custom, index) => (
                            <li key={index} className="list-group-item">
                              <div className="note-content d-flex">
                                <span className="note-index mx-2">
                                  <b>{index + 1}.</b>
                                </span>
                                
                                <div className="border p-1">
                                  <div className="d-flex mb-2">
                                        <span>Title : </span>
                                      <input
                                        type="text"
                                        {...register(`leadCustomFieldsData.${index}.field_name`)}
                                        defaultValue={ custom.field_name }
                                        // onChange={(e) =>
                                        //   handleNoteTitleChange(
                                        //     index,
                                        //     e.target.value
                                        //   )
                                        // }
                                        className="note-text mx-2"
                                      />
                                  </div>

                                  {errors?.leadCustomFieldsData?.[index]?.field_name && <div className="invalid-feedback">{errors?.leadCustomFieldsData?.[index]?.field_name.message}</div>}      

                                  <div className="d-flex">
                                        <span>Field : </span>
                                      <textarea
                                        type="text"
                                        {...register(`leadCustomFieldsData.${index}.description`)}
                                        defaultValue={ custom.description }
                                        // onChange={(e) =>
                                        //   handleNoteContentChange(
                                        //     index,
                                        //     e.target.value
                                        //   )
                                        // }
                                        className="note-text mx-2" row="4" cols="40"
                                      />
                                  </div>
                                  {errors?.leadCustomFieldsData?.[index]?.description && <div className="invalid-feedback">{errors?.leadCustomFieldsData?.[index]?.description.message}</div>}          
                                </div>


                                <Tooltip title="Delete">
                                  {/* <IconButton onClick={() => { deleteNotes(note);}}> */}
                                  <IconButton onClick={() => { removeCustom(custom);}}>
                                    <FeatherIcon icon="trash-2" width="18" color="red"/>
                                  </IconButton>
                                </Tooltip>
                                
                              </div>
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <>
                          <p style={{ fontSize: '12px' }}>No data</p> 
                        </>
                      )}
                      </div>
                    </div>

                    <div className="form-group col-lg-12">
                      <div> 
                      <label> Recordings </label>
                        <Tooltip title="Add Recording">
                          <IconButton >
                            <FeatherIcon icon="plus" width="20" color="blue" />
                            <input type="file" accept="audio/*" className="form-control"
                              multiple
                              onInput={recodingchnge}
                            />
                          </IconButton>
                        </Tooltip>
                      </div>
                      {selectedRecordings.length > 0 ? (
                        <div>
                          <ul className="list-group">
                            {selectedRecordings.map(
                              (recordings, index) => (
                                <li key={index} className="list-group-item">
                                  <div className="note-content">
                                    <div className="row">
                                      <span className="note-index">
                                        <b>{index + 1}.</b>
                                      </span>
                                      <audio
                                        src={
                                          updatedRecoredContent[recordings.id] ||
                                          `${process.env.REACT_APP_AWS_S3_URL}` + `${process.env.REACT_APP_LEAD_RECORDINGS}` + '/' + recordings.recordings_name
                                        }
                                        controls
                                        onPlay={(e) => handlePlay(e.target)}
                                      />
                                    </div>
                                    <input type="text"
                                      defaultValue={
                                        recordings.dummy_recording
                                      }
                                      onChange={(e) =>
                                        handleRecordingContentChange(
                                          index,
                                          e.target.value
                                        )
                                      }
                                      className="note-text mx-2"
                                    />
                                    <Tooltip title="Delete">
                                      <IconButton
                                        onClick={() => {
                                          // deleteRecordingIdWise(recordings.id);
                                          deleteRecordings(recordings)
                                        }}
                                      >
                                        <FeatherIcon
                                          icon="trash-2"
                                          width="18"
                                          color="red"
                                        />
                                      </IconButton>
                                    </Tooltip>
                                    <div>
                                      <div className="row">
                                        <div className="form-group col-lg-6">
                                          {/* <label> Virtual </label> */}
                                          <div className="form-control">
                                            <div className="form-check form-check-inline ">
                                              <input
                                                defaultChecked={
                                                  recordings.recording_show == "Visible" ? true : false
                                                }
                                                onChange={(e) =>
                                                  handleRecordingShowChange(
                                                    index,
                                                    e.target.value
                                                  )
                                                }
                                                className="form-check-input"
                                                type="radio"
                                                name={"recording_show_"+index}
                                                id="Visible"
                                                value={"Visible"}
                                              />
                                              <label className="form-check-label" htmlFor="Visible">
                                                {" "} Visible {" "}
                                              </label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                              <input
                                                defaultChecked={
                                                  recordings.recording_show == "Hidden" ? true :false 
                                                }
                                                onChange={(e) =>
                                                  handleRecordingShowChange(
                                                    index,
                                                    e.target.value
                                                  )
                                                }
                                                className="form-check-input"
                                                type="radio"
                                                name={"recording_show_"+index}
                                                id="Hidden"
                                                value={"Hidden"}
                                              />
                                              <label className="form-check-label" for="Hidden">
                                                {" "} Hidden {" "}
                                              </label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                              <input
                                                defaultChecked={
                                                  recordings.recording_show == "Visible at appt time" ? true : false 
                                                }
                                                onChange={(e) =>
                                                  handleRecordingShowChange(
                                                    index,
                                                    e.target.value
                                                  )
                                                }
                                                className="form-check-input"
                                                type="radio"
                                                name={"recording_show_"+index}
                                                id="VisibleApptTimeRecording"
                                                value={"Visible at appt time"}
                                              />
                                              <label className="form-check-label" for="VisibleApptTimeRecording">
                                                {" "} Visible at appt time {" "}
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              )
                            )}
                          </ul>
                        </div>
                      ) : (
                        <>
                          <p style={{ fontSize: '12px' }}>No data</p> 
                        </>
                      )}
                    </div>

                    <div className="form-group col-lg-12">
                      <div > 
                        <label> Images </label>

                        <Tooltip title="Add Images">
                          <IconButton >
                            <FeatherIcon icon="plus" width="20" color="blue" />
                            <input
                              type="file"
                              className="form-control"
                              accept="image/*,.gif" 
                              onInput={imgchnge} 
                              multiple
                            />
                          </IconButton>
                        </Tooltip>
                      </div>
                      {selectedImages.length > 0 ? (
                        <div>
                          <ul className="list-group">
                            <div class="row">
                              {selectedImages.map((img, index) => (
                                <div className="col-2" key={img}>
                                  <li
                                    key={index}
                                    className="list-group-item custom-list-item"
                                  >
                                    <div className="note-content">
                                      <div className="row" style={{ position: 'relative', display: 'inline-block' }}>
                                        <React.Fragment>
                                          <Image
                                            className="m-1 shadow-sm"
                                            style={{ width: 150, height: 150, cursor:'pointer' }}
                                            src={`${process.env.REACT_APP_AWS_S3_URL}` + `${process.env.REACT_APP_LEAD_IMAGES}` + '/' + img}
                                            onClick={()=>handleImageClick(`${process.env.REACT_APP_AWS_S3_URL}` + `${process.env.REACT_APP_LEAD_IMAGES}` + '/' + img)}
                                            rounded
                                          />
                                          {/* <Tooltip title="Delete">
                                            <IconButton
                                              className="fa-stack the-wrapper icon-tag"
                                              onClick={() => {
                                                // deleteImagessIdWise(img.id);
                                                deleteImage(img);
                                              }}
                                            >
                                              <FeatherIcon
                                                icon="x"
                                                width="18"
                                                color="red"
                                              />
                                            </IconButton>
                                          </Tooltip> */}
                                          <div className="justify-content-between d-flex">
                                              <Tooltip title="Delete" placement="top">
                                                <IconButton style={{ position: 'absolute', top: 0, right: 30 }} className="fa-stack the-wrapper icon-tag" onClick={() => {deleteImage(img)}}>
                                                    {/* <FeatherIcon icon="x" width="18" color="red" /> */}
                                                    <img src={process.env.PUBLIC_URL + "/assets/images/cancel.png"} height={"20px"} width={"20px"} />
                                                </IconButton>
                                              </Tooltip>
                                              <Tooltip title="Download" placement="top">
                                                  <IconButton style={{ position: 'absolute', top: 0, right: 0 }} className="fa-stack the-wrapper icon-tag" onClick={() => {handleImageDownload(img)}}>
                                                      <FeatherIcon icon="download" width="18" color="blue" />
                                                  </IconButton>
                                              </Tooltip>
                                          </div>
                                        </React.Fragment>
                                      </div>
                                    </div>
                                  </li>
                                </div>
                              ))}
                            </div>
                          </ul>
                        </div>
                      ) : (
                        <>
                         <p style={{ fontSize: '12px' }}>No data</p> 
                        </>
                     )}
                    </div>

                    <div className="form-group justify-content-center text-center">
                      <button
                        type="submit"
                        className="btn btn-primary waves-effect waves-light m-2"
                      >
                        Save
                      </button>
                      <button className="btn btn-danger" onClick={redirect}>
                        {" "}
                        Cancel{" "}
                      </button>
                    </div>
                  </form>
                }

                {/* <br /><br /> */}
                {/* </div> */}
                {/* <Link to='/leadsList' className="btn btn-primary btn-lg common_hover">
                                        <i className="mdi mdi-chevron-left"></i> <span> back </span>
                                    </Link> */}
              </div>
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Body>
            <img src={modalImageSrc} alt="Full Screen" className="img-fluid" />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AddRecentSoldLead;

